.see-p-link--1 {
    width: 100%;
    height: 58px;

    position: relative;
    border-top: 1px solid #0b0e1116;
}

.CB-see-p-button--1 {
    width: calc(100% + 28px);
    color: var(--green);
    font-weight: bold;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 0 32px;
}

.see-p-link--2 {
    width: 100%;
    height: 24px;
    margin-top: 14px;
    position: relative;
}


.CB-see-p-button--2 {
    width: calc(100% + 28px);
    color: #0b0e1199;
    font-size: 16px;

    display: flex;
    justify-content: space-between;
    position: absolute;
    left: -14px;
    bottom: -16px;
    padding: 12px 32px 0 32px;
    border-top: 1px solid #0b0e1116;
}

.CB-see-p-button--2:hover {
    color: var(--hover);
}