.SB-list-scroll {
    margin: 0 20px !important;
    border-radius: 12px;
}

.SB-list-scroll a li {
    padding: 0;
    margin: 2px 0 0 0;
}

