.Spinner {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 4;
}

.loader {
  --loader-weight: 3px
}

.loader {
  border: var(--loader-weight) solid transparent;
  border-radius: 100%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}