#LoginContainer {
    background-color: #fff;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}

#LoginContainer h2 {
    color: var(--green);
}

#LoginContainer form {
    width: 100%;
}

.LG-get-an-account {
    display: none;
}

.LG-blocks {
    width: 25%;
    padding: 20px 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LG-enter {
    border-right: 1px solid #c4c4c4;
}

@media only screen and (max-width: 960px) {
    .LG-create {
        display: none;
    }

    #LoginContainer {
        flex-direction: column;
    }

    #LoginContainer h2{
        text-align: center;
    }

    .LG-get-an-account {
        display: block;
    }

    .LG-blocks {
        width: 100%;
        padding: 0;
    }

    .LG-enter {
        border: none;
    }

    #LoginContainer form {
        width: 84%;
    }
}