.soc-green-top,
.soc-white-card-wrap,
.soc-form,
.soc-border-top,
.soc-add,
#soc-text-input,
.soc-abs-green {
    --soc-green-height: 200px;
    --white-card-card: 180px;
    --white-half: calc(var(--white-card-card) / 2);
    --containers-w: 620px;
    --height-c: 70px;
}

.soc-white-card-container,
.soc-w-right,
.soc-w-avatar-wrap {
    --soc-w-padding: 20px;
    --soc-avatar: 60px;
}

.soc-bullet-days--selected {
    --soc-linear-g: linear-gradient(26deg, var(--dark), rgba(34, 45, 54, .80));
}

.soc {
    position: relative;
    padding-bottom: 133px;
}

.soc-green-top {
    background-color: var(--green);
    width: 100%;
    height: var(--soc-green-height);
}

.soc-white-card-wrap {
    width: 100%;
    position: absolute;
    top: calc(var(--soc-green-height) - var(--white-half));

}

.soc-white-card {
    height: fit-content;
    width: var(--containers-w);
}

.soc-white-card-container {
    padding: var(--soc-w-padding);
    display: flex;
    flex-direction: column;
}

.soc-w-top {
    display: flex;
}

.soc-w-bottom {
    margin-top: 18px;
    padding-top: 18px;
    border-top: 1px solid var(--border);
    display: flex;
    justify-content: space-between;
}

.soc-w-bottom-b-b {
    margin-bottom: 37px;
}

.soc-w-bottom--bigger-font p {
    font-size: 16px !important;
}

.soc-w-bottom p {
    margin: 0;
    font-size: small;
    color: var(--dark);
}

.soc-w-bottom strong {
    margin: 0;
    font-size: larger;
    color: var(--dark);
}

.soc-form {
    margin-top: calc(var(--white-half) + 90px);
    width: var(--containers-w);
    display: flex;
    flex-direction: column;
}

.soc-f-btn-margin-top-p-relative {
    margin-top: 8px;
    position: relative;
}

.soc-add {
    width: var(--height-c);
    height: var(--height-c);
}

.soc-form-btn {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 0 20px;
}

.soc-form-btn strong {
    color: var(--dark);
}

.soc-form-btn span,
.soc-add span {
    font-size: 25px;
    color: var(--dark);
}


.soc-w-avatar-wrap {
    width: var(--soc-avatar);
    height: var(--soc-avatar);
    position: relative;
}

.soc-w-right {
    --soc-w-r: 10px;
    padding-left: var(--soc-w-r);
    width: calc(100% - (var(--soc-w-padding) + var(--soc-avatar) - var(--soc-w-r)));
}

.soc-title {
    font-size: 20px;
    margin: 0 0 4px 0;
}

.soc-title-pro {
    font-size: 20px;
    margin: 0;
}

.soc-title-2 {
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.soc-gray {
    padding: 8px 36px;
    border-radius: 8px;
}

.soc-title-2 h6 {
    margin: 0;
    padding: 0;
}

.soc-f-h3 {
    margin: 8px 0 0 0 !important;
}

.soc-h3 {
    font-size: medium;
    color: var(--dark);
    margin: 0 0 10px 0;
}

.soc-p {
    font-size: 14px;
    color: var(--dark);
    margin: 0;

    max-width: 100%;
}

.soc-f {
    margin-top: 18px;
    display: flex;
    flex-direction: column;

}

.soc-f-p {
    margin: -4px 0 10px 0;
}

#soc-text-input {
    text-align: start;
    height: var(--height-c);
    width: calc(100% - 40px);
    padding: 20px;
    margin: 0;
    border-radius: 8px 8px 0 0;
    text-indent: 2px;
    border: none;
    font-family: sans-serif;
}

#soc-text-i-btn {
    width: 100%;
    height: 60px;
    border-top: 1px solid var(--border);
    border-radius: 0 0 8px 8px;
}

.soc-pro-btn {
    position: fixed;
    z-index: 2;
    width: 100vw;
    height: fit-content;
    background-color: white;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
}

.soc-pro-btn button {
    height: 50px;
    width: 90%;
    border-radius: 8px;
    background-color: var(--dark);
    color: white;
}

.soc-pro-btn p {
    font-size: smaller;
}

.soc-border-top {
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #c4c4c4;
    width: var(--containers-w);
}

.soc-pro {
    width: 100%;
    background: linear-gradient(-20deg, #222D36 36%, #1D384F 100%);
    display: flex;
    flex-direction: column;
    align-items: center;

}

.soc-lock {
    display: flex;
}

.soc-lock:first-child {
    border-bottom: 1px solid var(--border);
}

.soc-abs-green {
    position: absolute;
    left: 5px;
    top: 0;
    height: var(--height-c);
}

.soc-abs-green span {
    background-color: var(--green);
    height: 42px;
    width: 4px;
    border-radius: 100px;
}

.soc-b {
    height: 30px;
    flex: 1;
    background-color: var(--border);
    display: flex;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    color: var(--dark);
    margin-left: 10px;
    opacity: .5;
}

.soc-lock-b {
    background-color: var(--border);
    height: 56px;
    width: 40px;
    margin: 10px;
    border-radius: 6px;
    opacity: 0.7;
}

.soc-d-btn2 {
    height: 40px;
    width: 60px;
}

.soc-description-absolute-btns {
    position: absolute;
    display: flex;
    justify-content: space-between;
    bottom: -65px;

    background-color: var(--dark);
    width: calc(100% - 6px);
    padding: 3px;
    border-radius: 8px;
    height: 40px;
}

.soc-description textarea:focus~.soc-description-absolute-btns #soc-description-btn {
    opacity: .5;
    pointer-events: none;
}

.soc-description textarea,
.soc-text-input {
    resize: none;
}

#soc-description-btn {
    margin-left: 15px;
}

.soc-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    width: 100%;
    justify-content: center;
    row-gap: 10px;
    justify-content: space-between;
}

.soc-grid-div {
    background-color: white;
    padding: 13px 35px;
    border-radius: 6px;
}

.soc-grid-div--selected {
    background: var(--dark);
    color: white;
}

.soc-hours-btn {
    padding: 10px 0;
    width: 30%;
    background-color: white;
    border-radius: 8px;
    height: 100%;
    font-size: 20px;
}

.soc-hours {
    position: relative;
    background-color: rgba(34, 45, 54, .15);
    width: calc(100% - 6px);
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    padding: 3px;
    border-radius: 8px;
    height: 40px;
    color: var(--dark);
}

.soc-month-name {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.soc-month-name--right,
.soc-month-name--left {
    width: fit-content;
    position: absolute;
    top: 0;
}

.soc-month-name--right {
    margin-right: 30px;
    right: 0;
}

.soc-month-name--left {
    margin-left: 30px;
    left: 0;
}

.soc-orange,
.soc-month {
    width: 95vw;
}

.soc-bullet-days {
    background-color: white;
    margin-right: 10px;
    height: 86px;
    width: 82px;
    border-radius: 6px;
    color: #222D36;
}

.soc-bullet-days p {
    margin: 0;
}

.soc-bullet-days-name {
    width: 100%;
    height: 30px;
    text-indent: 10px;
    display: flex;
    align-items: center;
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid var(--border);
    color: #222D36;
    font-size: 14px;
}

.soc-bullet-days-number {
    height: calc(100% - 40px);
    text-indent: 10px;
    display: flex;
    align-items: flex-end;
    font-size: 28px;
}

.soc-bullet-days--selected {
    background: var(--soc-linear-g);
    color: white;
}

.soc-bullet-days-name--selected {
    color: white;
    border: 1px solid transparent;
    background-color: #ffffff25;
}

.soc-date-scroll {
    display: flex;
    overflow-x: scroll;
    align-items: center;

    margin-bottom: 20px;
    padding-bottom: 10px;
}

.soc-description-main-btn {
    height: 40px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.soc-captalize {
    text-transform: capitalize;
}

.soc-loc-x {
    background-color: white;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-right: 20px;
    padding: 10px;
    text-align: center;
    min-width: 120px;
    height: 150px;
    border-radius: 8px;
    color: var(--dark);
}

.soc-loc-x--selected {
    background-color: var(--dark);
    color: white;
}

.soc-loc-x span {
    height: 60px;
    width: 60px;
    border-radius: 100px;
    background-color: #00000015;
}

.soc-loc-x--selected span {
    background-color: #ffffff40;
}

.orders-resize {
    width: fit-content;
}

#OrdersNavigation {
    position: relative;
    width: 100%;
    background-color: white;
}

@media only screen and (max-width: 960px) {


    .orders-resize {
        width: 100%;
    }

    .soc-green-top,
    .soc-white-card-wrap,
    .soc-form,
    .soc-border-top {
        --containers-w: 95%;
    }
}