#AboutUs {
    width: 100%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 100px 0;
}

.sobre_titulo {
    display: flex;
    align-items: center;
}

.sobre_titulo-comserv {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.sobre_texto p {
    margin-top: 15px;
    font-size: 20px;
    color: #222D3690;
    padding: 15px 0 0 0;
}

.sobre_titulo h2 {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
}

.sobre_titulo span {
    font-weight: 100;
    font-size: 34px;
}

.sobre_titulo h2,
.sobre_titulo span {
    margin: 0;
    color: #222D36;
}

.sobre_titulo_img_two {
    height: 80px;
    margin-right: 20px;
}

.sobre_titulo_img_one img {
    height: 48px;
}

.gradient_absolut_left {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, #faf4f0);
}

.gradient_absolut_right {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-image: radial-gradient(transparent 5%, transparent 15%, #eeeeee 70%);

}

.gradient--left {
    width: 60%;
    padding-right: 20px;
}

.gradient--right {
    position: relative;
}

.gradient--right img {
    max-width: 100%;
}
.AboutForm {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: -50px;
}
.AboutForm-input {
    width: 100%;
}
.AboutForm-btn {
    width: 24%;
}
@media only screen and (max-width: 960px) {

    #AboutUs {
        margin: 100px 0 80px 0;
    }

    .gradient--center {
        display: none;
    }

    .gradient--right {
        display: none;
    }

    .gradient--left {
        text-align: center;
        width: 84%;
        margin-left: 8%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sobre_texto {
        text-align: left;
    }

    .sobre_titulo {
        width: 100%;
    }
}