.ResetPasswordContainer {
    width: 360px;
    height: 320px;
 
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.RPC-span {
    font-weight: bold;
    font-size: 24px;
}

.RPC-p {
    font-weight: lighter;
}