#GoBack {
    position: absolute;
    height: 58px;
    background-color: #00ae74;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: -1px;
    z-index: 0;
    display: none;
}

#GoBack button {
    width: 34px;
    height: 34px;
    border-radius: 100%;
    color: white;
    overflow: hidden;
}

@media only screen and (max-width: 960px) {
    #GoBack {
        display: flex;
    }
}