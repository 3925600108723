.banners-image-wrap {
    min-height: 100%;
    max-width: 100%;
    z-index: 2;
}

.banners-img {
    min-height: 100%;
    max-height: 460px;
    max-width: 100%;
    z-index: 2;
}

.banners-img-main {
    height: 420px;
}

.banners-image-wrap-details {
    height: 100%;
    width: 100%;
}

.banners-img-details {
    min-width: 480px;
    min-height: 440px;
}


.banners-blur {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    position: absolute;
    flex: 1;
    -webkit-filter: blur(20px);
    -moz-filter: blur(20px);
    -o-filter: blur(20px);
    -ms-filter: blur(20px);
    filter: blur(20px);
    border-radius: 8px;
}

@media only screen and (max-width: 960px) {
    .banners-image-wrap-main {
        max-height: 220px;
    }


    .banners-img-main {
        width: 100%;
        height: 100%;
        border-radius: 0;
    }

    .banners-img-details {
        min-width: 100%;
    }
}