.subwork-details {
    background-color: white;
}

.subwork-details-img-wrap {
    width: 100%;
    height: 260px;
    background-color: #c4c4c4;
}

.subwork-details-img {
    height: 100%;
}

.subwork-d-button {
    background-color: var(--dark);
    color: white;
    padding: 15px 120px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.subwork-d-date {
    height: 40px;
    padding: 0 40px;
    border-radius: 8px;
    background-color: var(--border);
}

.subwork-price-btn-wrap {
    width: fit-content;
}

@media only screen and (max-width: 960px) {
    .subwork-price-btn-wrap {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .subwork-details-img {
        width: 100%;
    }

    .subwork-details {
        min-height: calc(100vh - 150px);
    }

    .subwork-d-button {
        color: white;
        flex: 1;
        padding: 15px 0;
        border-radius: 8px;
        font-weight: bold;
        font-size: 16px;
    }
}