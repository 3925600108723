#Profession {
    width: 32%;
    height: 450px;
    max-height: 70vh;
    cursor: pointer;

    background-color: #c4c4c4;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    box-shadow: 0 7px 16px 0 rgb(0 0 0 / 30%), 0 1px 3px 0 rgb(0 0 0 / 20%);
}

#Profession:hover {
    box-shadow: 0 7px 26px 0 rgb(0 0 0 / 35%), 0 2px 6px 0 rgb(0 0 0 / 30%);
}

#Profession:hover span {
    display: flex;
}

.Prof-name,
.Prof-gradient {
    --prof-height: 61px
}

.Prof-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(219.68deg, rgba(255, 255, 255, 0) 29.72%, rgba(34, 45, 54, 50%) 80.72%, rgba(34, 45, 54, 70%) 100%);

    display: flex;
    align-items: flex-end;
    border-radius: 20px;
}

.Prof-name {
    width: 100%;
    height: var(--prof-height);
    font-size: 16px;
    border-radius: 0 0 8px 8px;

     
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}

.Prof-name h2 {

    color: #fff;
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;

    margin: 0 14px;
    padding: 15px 30px;
    border-radius: 16px;
    background-color: rgba(34, 45, 54, 1);
     
    display: flex;
    align-items: center;
    justify-content: center;
}

.Prof-name span {
    padding: 0 0 0 10px;

    display: none;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 960px) {
    #Profession {
        width: 90%;
        height: 200px;
        background-size: 100%;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 12px;

    }

    #Profession:hover {
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
    }

    .Prof-gradient {
        height: 100%;
        border-radius: 12px;
    }

    .Prof-name {
        width: 100%;
        height: 42px;
        align-items: center;

        background: linear-gradient(180deg, rgba(77, 101, 105, 0) 0%, rgba(77, 101, 105, 0) 3.65%, rgba(77, 101, 105, 0.8) 68.75%);
    }

    .Prof-name h2 {
        background-color: rgba(34, 45, 54, 0.90);
        padding: 15px 30px;
        border-radius: 16px 0 0 0;
        font-size: 12px;
        margin: 0;
    }

    .Prof-name span {
        display: flex;
    }

    #Profession:hover span {
        display: inherit;
    }
}