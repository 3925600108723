#NavigationBar {
    position: fixed;
    z-index: 89;
    width: 100vw;
    height: fit-content;
    bottom: 0;
    left: 0;
    background-color: white;
    box-shadow: 0 -4px 8px #00000010;
    display: flex;
}

#NavigationBar a,
.nv-like-a {
    width: 20%;
    font-size: 20px;
    color: var(--dark);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#NavigationBar a p {
    font-size: 11px;
    font-weight: normal;
    margin: 2px 0 0 0;
    opacity: .7;
}

.nav-add-btn {
    background-color: var(--green);
    box-shadow: 0 8px 12px -8px var(--hover);
    border-radius: 16px;
    color: white;
    height: 52px;
    width: 52px;
    font-size: 26px;
    margin-top: -30px;
}