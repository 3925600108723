.SideBar-Container {
    position: fixed;
    left: 0;
    top: 0;
    margin-top: var(--header-height);
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    padding: 0 20px 20px 0px;
    display: flex;
    z-index: 99;
}

#SideBar {
    height: calc(100vh - var(--header-height));
    width: 400px;
    position: relative;
    box-shadow: 10px 0 14px 0 rgb(0 0 0 / 20%);
    position: relative;
    overflow-y: scroll;
}

.SB-close-button {
    width: 40px;
    height: 40px;
    background-color: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #00000040;
    font-size: 30px;

     
    display: flex;
    align-items: center;
    justify-content: center;
}

.SB-list {
    margin: 0;
    padding: 0;
}

.SB-list-li-user {
    background-color: #ededed;
    padding: 20px;
    height: 160px;
}

.SB-list-li-user-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;

    padding: 10px 20px 10px 10px;
    border-radius: 100px;
}

.SB-list-li-user-info-hover:hover {
    background-color: #00000010;
    cursor: pointer;
}

.SB-list-li-user-text {
    color: #3f3f3f;
    font-weight: bold;
    margin-left: 20px;
    font-size: .96em;
}
.SB-list-li-user-text h5{
    color: #3f3f3f90;
    font-weight: bold;
    margin: 0 0 7px 0;
}

.SB-list-li-user-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.SB-btn {
     
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: bold;
    font-size: 1em;
    width: 46%;
    height: 42px;
    border-radius: 16px;
    border: 2px solid var(--green);

}

.SB-list-li-user-btn-login {
    color: rgb(248, 248, 248);
    background-color: #222D36;
    border: 2px solid #222d36;
    text-shadow: 1px 1px 2px #00000020;
}

.SB-list-li-user-btn-signin {
    border: 2px solid rgba(34, 45, 54, 0.7);
    color: rgba(34, 45, 54, 0.7);
    background-color: transparent;
}

.SH-li-forward {
    color: #646464;
}

.SB-fill-rest {
    flex: 1;
}

@media only screen and (max-width: 960px) {

    .SideBar-Container {
        margin-top: 0;
        background-color: #00000020;
    }

    #SideBar {
        height: 100%;
        width: 95%;
    }
}