#DetailsContainer {
    width: 100vw;
    margin: -40px 0 40px 0;
    display: flex;
    position: relative;
    display: flex;
    flex-direction: column;
}

.d-img-wrap {
    height: 80px;
    width: 80px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
}

.d-subworks-wrap {
    padding: 10px 30px;
}

.d-subworks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 16px;
    cursor: pointer;
    color: var(--dark);
}

.d-subworks:hover {
    background-color: var(--border);
}

.d-subworks-wrap .d-subworks-border-top:not(:first-child) {
    border-top: 1px solid var(--border);
}

.d-subwork-description {
    opacity: .8;
    font-size: .9em;
    line-height: 1.2;
    max-width: calc(90% - 90px);
}

#DetailsContainer main {
    height: 100%;
    width: 66vw;
    margin-left: 4vw;
}

#DetailsContainer aside {
    width: 30%;
    height: calc(100vh - (var(--both-height) - 40px));

    display: flex;
    align-items: flex-start;
    justify-content: center;

}

.DC-aside-fixed {
    right: 0;
    width: 29vw !important;
    height: 100vh !important;
    z-index: 4;
}

.DC-card-A {
    display: flex;
    flex-flow: column;
    padding: 0 40px 40px 40px;
    position: relative;
}

.DC-card-A h2 {
    padding: 0;
    margin: 0;
}

#zero-DC {
    display: none;
}


.b-DC-one {
    width: calc(100% + 80px);
    margin: -41px 0 0 -41px;


}

.b-DC-selector {
    z-index: 2;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #15181899;
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 20px 10px 20px;
}

.b-DC-selector li {
    margin: 10px 0;
    width: 58px;
    height: 58px;
    border-radius: 8px;
    overflow: hidden;
    opacity: .7;
    cursor: pointer;
    border: 2px solid transparent;
}

.b-DC-selector li:hover {
    border: 2px solid #c4c4c4;
}

.b-DC-selector li img {
    object-fit: cover;
}

.b-DC-selector-current {
    opacity: 1 !important;
    border: 2px solid var(--hover) !important;
}

.b-DC-selector li img {
    width: 100%;
    height: 100%;
}

#DC-remote-button {
    border: 2px solid var(--hover);
    height: 40px;
    width: 160px;
    background-color: transparent;
    border-radius: 100px;
    color: var(--hover);
    font-weight: bold;

    display: flex;
    align-items: center;
    justify-content: center;
}

#DC-remote-button span {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 24px;
    margin-left: 8px;
}



.DC-card-B-content {
    display: flex;
}

.DC-card-B-content div {
    display: flex;
    align-items: center;
}

.DC-card-B-content div:first-child {
    margin-bottom: 0px;
}

.DC-card-B-content span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    width: fit-content;
    margin-right: 12px;
}

.DC-card-B-content div {
    width: 50%;
}

.DC-card-B,
.DC-card-C,
.DC-card-D {
    padding: 0 40px 40px 40px;
}

ul,
li,
li h4,
li p {
    margin: 0;
    padding: 0;
}

.DC-card-C-content ul {
    display: grid;
    grid-template-columns: auto auto;
}

.DC-card-C-content ul li {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
}

.DC-card-D-content ul li {
    width: 100%;
    display: flex;
}

.DC-card-D-content ul li p,
.DC-card-D-content ul li h4 {
    width: 50%;
    margin-bottom: 20px;
}

.DC-card-C-content ul li h4 {
    margin-bottom: 20px;
}

.price-holder {
    width: 100%;
    height: 50px;
    border-radius: 14px;
    border: 2px solid #898e92;
    padding: 4px;
}

#c-DC {
    width: 100%;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 12px 0;

}

#c-DC p {
    text-decoration: line-through;
    color: rgba(34, 45, 54, 40%);
    font-weight: bold;
    margin: 0;
}

#c-DC h4 {
    background-clip: text;
    color: #222D36;
    font-size: 16px;
    margin: 0;
    padding: 0 20px 0 10px;
}

#c-DC strong {
    margin-top: -4px;
    font-size: 19px;
    color: #00694699;
}

.CB-edit-button {
    width: 100%;
    height: 44px;
    border-radius: 6px;
    background-color: #fff;
    color: #000;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #c4c4c4;
}

#a-DC {
    color: #151818;
    font-weight: 700;
    font-size: 24px;
    margin: 42px 0 0px 0;
}

.DC-show-number {
    width: 260px
}

#d-DC {
    color: #15181899;
}

.DC-max-width {
    width: 90%;
    order: 3;
}

.DC-Title {
    width: 60%;
    height: 60px;
    display: flex;
    align-items: center;
    border-bottom: 2px solid #DEE6EB;
    margin-bottom: 40px;
    padding-bottom: 16px;
    position: relative;
}

.DC-title-gradient {
    position: absolute;
    right: -60%;
    bottom: -2px;
    background: linear-gradient(to right, #DEE6EB, white);
    height: 2px;
    width: 60%;
}

.DC-Title h3 {
    font-weight: 500;
    display: flex;
    margin-bottom: -12px;
    padding: 0 20px 8px 0;
    color: #4d5153;
    text-transform: uppercase;
}

.DC-card:not(:first-child) {
    margin-top: 30px;
}

.DC-card-D-divider {
    display: flex;
}

.D-content-wrapper {
    width: calc(100% - 300px);
    margin-left: 60px;
}

.DC-card-D-map {
    display: block;
    width: 220px;
    height: 240px;
    margin-top: -41px;
    margin-left: -12px;
    margin-bottom: 20px;
    position: relative;

    border-radius: 12px;

    overflow-y: hidden;
}

.DC-card-D-map img {
    width: 100%;
    height: 100%;
    background-position: center;
}

.DC-open-map {
    font-size: 16px;

    margin: 0 0 10px 0;
    border: none;
    background-color: #222D36;
    border-radius: 8px;
    padding: 14px 40px;
    color: white;
    font-weight: normal;
    box-shadow: 0px 4px 8px #222D3630;
}

.DC-open-map span {
    margin: 0 0 0 6px;
}

.DC-check-list {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 14px;
}

.a-dc-wraper-1 {
    background-color: transparent;
    width: calc(100% + 80px);
    margin-left: -40px;
    z-index: 2;
}

.a-dc-wraper-2 {
    border-radius: 50px;
    background-color: white;
    width: 100%;

}

.a-dc-wraper-3 {
    width: 90%;
}

.DC-cities {
    color: #64646499;
    font-weight: bold;
    font-size: 12px;
    background-color: #ececec;
    margin: 16px 16px 0 0;
    padding: 12px 40px;
    border-radius: 6px;
    white-space: nowrap;
    width: fit-content;
}

.a-share-wrapper {
    position: absolute;
    right: -54px;
    top: -82px;
}

.social-media-logos {
    display: flex;
    justify-content: space-around;
    margin: 46px 0;
}

.dc-open-map-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.dc-d-divider {
    display: flex;
    padding-top: 30px;
}


#details-main {
    z-index: 2;
    min-height: 100vh;
    margin-top: 100px;
}

.TopProfDetails {
    display: none;
}
.DListTitleWrap {
    width: 100%;
}

.details-see-more-bottom-grid {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
}
.card156-tmp-copy {
    background-color: #fff;
    border: 1px solid #4d515324;

    box-shadow: 0 2px 9px 0 rgb(156 184 210 / 40%);
    border-radius: 8px;
}

.DetailsSubworksList {
    width: 100%;
}


@media only screen and (max-width: 1250px) {
    #DetailsContainer aside {
        width: 28%;
        margin-left: 2%;
    }

    #DetailsContainer main {
        width: 62vw;
        margin-left: 4vw;
    }

}

@media only screen and (max-width: 960px) {
    .DListTitleWrap {
        width: 90%;
    }
    
    .TopProfDetails {
        display: flex;
        padding: 80px 0 20px 0;
        position: relative;
    }

    #DetailsContainer {
        width: 100%;
        border-radius: 0;
        margin: 0;
        background-color: white;
    }

    #details-main {
        min-height: fit-content;
        margin-top: 0;
    }

    .card156-tmp-copy {
        box-shadow: none !important;
        border-radius: 0 !important;
        border: none !important;
    }

    .DetailsSubworksList {
        width: 88%;
    }

    .d-subworks-wrap {
        padding: 0;
    }

    .d-subworks {
        padding: 20px 0;
    }

    .d-subworks:hover {
        background-color: inherit;
        color: inherit;
    }

    .d-subwork-description {
        max-width: 100%;
    }

    .social-media-logos li {
        width: 50vw;
    }

    .social-media-logos {
        flex-direction: column;
        align-items: center;
    }

    .social-media-logos a:not(:first-child) {
        margin-top: 34px;
    }

    #dc-social-media {
        padding-bottom: 0;
    }

    .DC-open-map {
        margin: 0;
    }

    .D-content-wrapper {
        width: 100%;
        margin-left: 0;
    }

    .dc-d-divider {
        padding-top: 0;
        flex-direction: column;
    }

    .dc-open-map-wrapper {
        justify-content: center;
        align-items: center;
    }

    .a-share-wrapper {
        position: absolute;
        width: 90vw;
        top: -24px;
        left: 0;
    }

    #DetailsContainer main {
        width: 100vw;
        margin-left: 0px;
        margin-top: 0px;
    }

    .a-dc-wraper-1 {
        background-color: transparent;
        width: 100vw;
        margin-left: -5vw;
        margin-top: 0px;
    }

    .DC-check-list {
        grid-template-columns: auto;
    }

    .DC-card-D-map {
        display: block;
        margin-bottom: 20px;
        position: relative;
        height: 110px;
        overflow-y: hidden;
        border-bottom: 1px solid #eee;
        width: 101vw;
        margin-top: 0;
        margin-left: -21px;
        border-radius: 0;
    }

    .DC-card-D-map img {
        height: auto;
    }

    .DC-title-icon {
        display: none;
    }

    .DC-title-gradient {
        display: none;
    }

    .DC-Title {
        padding: 5px 0 0px 0;
        margin: 0 0 30px 0;
        border-bottom: 1px solid #DEE6EB;
        width: 100%;
    }

    .DC-Title h3 {
        text-transform: none;
        padding: 0;
        margin: 0;
        border-bottom: 0px solid transparent;
        font-weight: 700;
        text-transform: none;
    }

    .DC-title-no-bottom {
        margin-bottom: 0;
        border-bottom: 0px solid transparent;
    }


    .DC-card {
        background-color: #fff;
        padding: 0 20px 30px 20px;
        border-bottom: 0.07rem solid rgba(0, 0, 0, .08);
        border-top: 0.07rem solid rgba(0, 0, 0, .08);
        border-left: 0px solid transparent;
        border-right: 0px solid transparent;
        border-radius: 0;
        box-shadow: 0 8px 8px transparent;
    }

    .DC-card:not(:first-child) {
        margin-top: 24px;
    }


    .b-DC-selector {
        display: none;
    }

    #DetailsContainer aside {
        display: none;
    }

    .DC-card-A {
        margin: -8px 0 0 0;
        border-radius: 14px 14px 0 0;
        background-color: white;
    }

    .DC-max-width {
        width: 100%;
        order: 3;
        border: 0px solid transparent;
        padding-bottom: 0;
    }

    #a-DC {
        max-width: 70%;
        font-weight: 700;
        font-size: 24px;
        order: 3;
        margin: -10px 0 0px 0;
    }

    .full-b-DC {
        min-width: 100% !important;
        border-radius: 0 !important;
        margin-top: -10px;
        margin-bottom: -10px;
        margin-right: 0px !important;
        margin-left: 0px !important;
    }

    #c-DC {
        order: 5;

    }

    #d-DC {
        order: 6;
    }

    #zero-DC {
        order: 3;


        border: 1px solid #eee;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.10);
        border-radius: 18px;
        width: calc(104% - 24px);
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        padding: 12px;
        margin: 20px 0 34px -2%;
        position: relative;
    }

    .zero-DC-img {
        width: 70px;
        height: 70px;
        position: relative;
        margin-right: 18px;
    }

    #zero-DC h6,
    #zero-DC h4 {
        margin: 0px;
    }

    #zero-DC h6 {
        color: #757575;
        margin-bottom: 4px;
    }

    .zero-DC-u-info {
        display: flex;

    }

    .zero-DC-chevron {
        margin-top: 1px;
        font-size: 18px;
        color: rgba(34, 45, 54, 30%);
    }

    #DC-remote-button {
        left: 10%;
    }

    .DC-card-B-content {
        display: block;
    }

    .DC-card-B-content div {
        width: 100%;
    }

    .DC-card-B-content div:first-child {
        margin-bottom: 24px;
    }

    .DC-show-number {
        width: 100%;
        margin-bottom: 16px;
    }

    .details-see-more-bottom-grid {
        overflow-x: scroll;
    }


    .details-see-more-bottom-grid li {
        margin: 18px;
    }

    .DBottomLi {
        display: none;
    }
}

@media only screen and (max-width: 1460px) and (min-width: 1170px) {
    .details-see-more-bottom-grid li:last-child {
        display: none;
    }
}

@media only screen and (max-width: 1170px) and (min-width: 960px) {
    .details-see-more-bottom-grid li:nth-child(n+4) {
        display: none;
    }
}