.languageButtom {
    width: 140px;
     border-style: none;
     height: 60px;
     cursor: pointer;
     background-color: #fff;
     border: 1px solid #c9c9c9;
     box-shadow: 0px 3px 8px #c9c9c990;
     font-weight: bold;
     color: #acacac;
}
.languageButtom-left {
     border-radius: 8px 0 0 8px;

}
.languageButtom-right {
     border-radius: 0 8px 8px 0;
}
.languageButtom:hover {
    background-color: #00AE74;
    border: 1px solid #00AE74;
    color: #fff;
}
