#CardsCase {
    width: 94%;
    margin-left: 3%;
    box-shadow: 7px 7px 16px 0 rgb(0 0 0 / 4%);
    border-radius: 8px;
    background-color: #fff;
}

.case-desc-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    color: #757575;
    font-size: 12px;

}

#CardsCase ul {
    display: grid;
}

.case-box--1 {
    height: 440px;
    grid-template-columns: auto auto;
}

.case-box--2 {
    height: fit-content;
    grid-template-columns: auto;
}

#CardsCase aside {
    height: 58px;
    display: flex;
    align-items: center;
}

#CardsCase aside strong {
    margin-left: 32px;
    color: #222D36;
}

.case-drawer--0 {
    border-color: #0b0e1116;
    border-style: solid;
    border-width: 1px 1px 1px 0;
}

.case-drawer--1 {
    border-color: #0b0e1116;
    border-style: solid;
    border-width: 1px 0px 1px 0;
}

.case-drawer--2 {
    border-color: #0b0e1116;
    border-style: solid;
    border-width: 0px 1px 0px 0;
}

.case-drawer-border-top {
    border-top: 1px solid #0b0e1116;
}


.case-banner {
    width: 100px;
    height: 100px;
    border: 1px solid #0b0e1116;
    position: relative;
    border-radius: 12px;

    overflow: hidden;
    position: relative;
    z-index: 1;
}


#CardsCase h5 {
    margin: 0;
    padding: 0;
}

#CardsCase p {
    margin: 6px 0 0 0;
    padding: 0;
}

.case-infos--1 {
    text-align: center;
    width: 80%;
}

.case-infos--2 {
    width: calc(100% - 160px);

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.case-li {
    width: 100%;
    height: 100%;
    display: flex;
}

.case-li--2 {
    align-items: center;
    justify-content: space-around;
    height: 150px;
}

.case-location {
    font-size: 13px;
    color: #757575;
    margin-top: 8px;
    font-weight: bold;

}

.case-location--2 {
    width: fit-content;
}
