.HIconsFixed {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.HIFix-location {
    height: 100%;

     
    display: flex;
    justify-content: center;
    align-items: center;
}

.HIFix-location-border {
     
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 11px;
    height: 60%;
    padding: 0px 14px 0px 8px;
    border-radius: 100px;
    background-color: transparent;
}

.HIFix-location-border:hover {
    background-color: #00000030;

}

.HIFix-location-icon {
    font-size: 20px;
    margin-right: 6px;

     
    display: flex;
    justify-content: center;
    align-items: center;
}

.HIFix-icons {
    color: #fff;

     
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 4px;
    cursor: pointer;
    font-size: 20px;
    border-radius: 100%;
    padding: 4px;
}

.HIFix-icons:hover {
    background-color:#00000030 ;
}

.HIFix-add {
    font-size: 34px;
}