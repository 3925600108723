#Location {
    width: 100%;
}

.Loc-strong {
    width: 100%;
    text-align: center;
    font-size: 24px;
}

@media only screen and (max-width: 960px) {
    #Location {
        width: 90vw;
    }

}