.professions-container {
    display: flex;
    justify-content: space-between;
}

@media only screen and (max-width: 960px) {
    .professions-container #Profession:not(:nth-child(2)) {
        display: none;
    }

    .professions-container {
        padding: 8px 0 0 0;
        justify-content: center;
        background: linear-gradient(180deg, #00AE74 0%, rgba(0, 174, 116, 0) 100%);
    }
}