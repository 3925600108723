.Details-user {
    background-color: #fff;
    position: relative;

    border-radius: 8px;
    z-index: 2;
    width: 360px;
    max-width: 90%;
    top: -158px;
    box-shadow: -8px 8px 8px #0b0e1110;
    
}

.Details-user h2 {
    font-size: 15px;
}

.Details-user h6 {
    color: #757575;
    font-size: 14px;
    padding: 14px 0 4px 0;
    font-weight: bold;
}

.Details-user p {
    color: var(--dark);
    opacity: .8;
    font-size: 14px;
    padding-top: 10px;
    width: 90%;
    overflow: hidden;
}

.DUser-wrapper {
    height: calc(100% - 59px);
    padding: 30px 0 38px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}


.DUser-wrapper-columns {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.DUser-img-wrap {
    border: 8px solid white;
    border-radius: 18px;
    box-shadow: -6px 6px 12px #00000024, 0px 0px 2px #00000010;
}

.DUser-img {
    width: 110px;
    height: 110px;
}

.DUser-informations {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.Details-user h6,
.Details-user h4,
.Details-user p {
    margin: 0;
}

.Details-user h4 {
    color: var(--dark);
    width: 80%;
}

.du-100 {
    width: 100%;
}

.dumax-90 {
    max-width: 90%;
}

@media only screen and (max-width: 960px) {

    .Details-user,
    .DUser-img-wrap {
        --fix-absolute-top: 50px
    }

    .Details-user {
        width: 90%;
        border-radius: 8px;
        top: auto;   
        background-color: white;
        margin-top: var(--fix-absolute-top);
        margin-bottom: 25px;
    }

    .DUser-wrapper {
        padding: 55px 0 35px 0;
    }

    .DUser-img-wrap {
        border: 2px solid white;
        border-radius: 12px;
        box-shadow: none;
    }

    .DUser-img {
        width: 90px;
        height: 90px;
    }

    .DUser-img-wrap {
        position: absolute;
        top: calc(-1 * var(--fix-absolute-top));
    }
}