.I {
    --I-height: 40px;
    border-radius: 8px;
}

.Input {
    background-color: #fff;
    height: 40px;
    text-indent: 13px;
    border: 1px solid #c9c9c9;
    z-index: 0;

    -webkit-appearance: none;
}

.TextArea {
    min-height: 5em;
    overflow: auto;
    padding: 15px;
    width: calc(100% - 30px) !important;
    line-height: 1.6;
    font-family: inherit;
}

.Eye {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 0 16px;
    cursor: pointer;
    height: calc(var(--I-height) + 4px);
    z-index: 2;

     
    display: flex;
    align-items: center;
    justify-content: center;
}

.Eye:hover {
    background-color: #00000025;
}

.Label {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    color: var(--dark);
    font-weight: 500;
}

.Text {
    margin: 0;
    padding: 0;
    font-weight: normal;
    color: var(--dark);
    margin-left: 8px;
}

.FormButton {
    height: calc(var(--I-height) + 4px);
    width: 100%;
    background-color: var(--dark);
    color: #fff;
    font-weight: bold;
    font-size: 15px;
    border: 1px solid var(--dark);
}

.Select {
    display: inline-block;
    line-height: 1.5em;
    padding: 0.5em 3.5em 0.5em 1em;

    margin: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;

    background-image:
        linear-gradient(45deg, transparent 50%, gray 50%),
        linear-gradient(135deg, gray 50%, transparent 50%),
        linear-gradient(to right, #ccc, #ccc);
    background-position:
        calc(100% - 20px) calc(1em + 6px),
        calc(100% - 15px) calc(1em + 6px),
        calc(100% - 2.7em) 0.8em;
    background-size:
        5px 5px,
        5px 5px,
        1px 1.5em;
    background-repeat: no-repeat;
}

.untouchable {
    cursor: alias !important;
    pointer-events: none !important;
    background-color: #d6d6d6 !important;
    border: 1px solid #d6d6d6 !important;
}

.Input::-webkit-datetime-edit-text {
    padding: 0 0.3em;
}

.Input::-webkit-calendar-picker-indicator {
    cursor: pointer;
    padding: 10px;
    border-radius: 100%;
}

.Input::-webkit-calendar-picker-indicator:hover {

    background-color: #00000020;

}

.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 26px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    padding: 13px !important;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

.noPointer {
    pointer-events: none;
}

.switch input:checked+.slider {
    background-color: var(--green);
}

.switch input:focus+.slider {
    box-shadow: 0 0 1px var(--green);
}

.tomato:checked+.slider {
    background-color: #ff634770 !important;
}

.tomato:focus+.slider {
    box-shadow: 0 0 1px #ff634770 !important;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

@media only screen and (max-width: 960px) {
    .Eye:hover {
        background-color: transparent;
    }

    .radios {
        justify-content: space-between;
    }
}