.CountrySelectorList {
    background-color: white;
    width: 100%;
}

.CountrySelectorList ul,
.CountrySelectorList li {
    width: 100%;

}
.CountrySelectorList button { 
    width: 100%;
    display: flex;
    padding: 0 40px;
}

.CountrySelectorList_button {
    display: flex;
    align-items: center;
    height: 60px;
    font-size: 18px;
    width: 100%;
    
}

.CountrySelectorList button span {
    opacity: .5;
    
}


.CountrySelectorList button:hover {
    background-color: var(--border);
}

.cselec_flag {
    margin-right: 15px;
}

.CountrySelectorList img {
    width: 30px;
    border-radius: 2px;
}

.cselec_p {
    opacity: .7;
}

.cslh_top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin: 0 40px;
}

.cslh_bottom {
    margin-top: 20px;
}


.cslh_bottom form,
.cslm_btn {
    position: relative;
    height: fit-content;
    width: calc(100% - 80px);
}

.cslh_bottom span,
.cslm_btn img {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 40px;
}

.cslh_bottom input,
.cslm_btn button {
    border: 1px solid var(--dark);
    height: 30px;
    border-radius: 8px;
    text-indent: 40px;
    width: 100%;
    display: flex;
}

.CountrySelectorListH {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid var(--border);
}

#CSSwitcher::-webkit-scrollbar {
    width: 0px;
}

#CSSwitcher::-webkit-scrollbar-track {
    background: transparent;
}

#CSSwitcher::-webkit-scrollbar-thumb {
    background: transparent;
}

#CSSwitcher::-webkit-scrollbar-thumb:hover {
    background: transparent;
}