@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,300;1,500&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eeeeee;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --green: #00AE74;
  --hover: #00e89a;
  --dark: #222D36;
  --border: #DEE6EB;

  --header-height: 58px;
  --subheader-height: 34px;
  --both-height: calc(var(--header-height) + var(--subheader-height));
}

button,
input {
  border-style: none;
}

button {
  cursor: pointer;
  background-color: transparent;
}

a,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

ul {
  list-style-type: none;
}

textarea:focus,
input:focus,
select:focus {
  outline: none;
}

.HIconsFixed {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.HIFix-location {
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.HIFix-location-border {
   
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 11px;
  height: 60%;
  padding: 0px 14px 0px 8px;
  border-radius: 100px;
  background-color: transparent;
}

.HIFix-location-border:hover {
  background-color: #00000030;

}

.HIFix-location-icon {
  font-size: 20px;
  margin-right: 6px;

   
  display: flex;
  justify-content: center;
  align-items: center;
}

.HIFix-icons {
  color: #fff;

   
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
  font-size: 20px;
  border-radius: 100%;
  padding: 4px;
}

.HIFix-icons:hover {
  background-color: #00000030;
}

.HIFix-add {
  font-size: 34px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.two-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}


.column {
  flex-direction: column;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fit {
  width: 100%;
  height: 100%;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

.hide-mobile {
  display: none !important;
}

.hide-widescreen {
  display: flex !important;
}

@media only screen and (max-width: 960px) {
  .hide-widescreen {
    display: none !important;
  }

  .hide-mobile {
    display: flex !important;
  }
}