.Cardboard-green {
    width: 100%;
    height: 240px;
    background-color: #00AE74
}

.Cardboard-center {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: transparent;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Cardboard-border {
    box-shadow: 0px 2px 6px #00000020;
    border-radius: 8px;
    background-color: #fff;
    padding: 50px;
}

@media only screen and (max-width: 960px) {
    .Cardboard-green {
        display: none;
    }
    
    .Cardboard-center {
        position: relative;
    }
}