

.Hide-popC {
    display: block;
}

#PopularProfessionals {
    width: 100%;
    display: flex;
}

#PopularProfessionals ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

#PopularProfessionals li {
    background-color: white;
    height: 290px;
    width: 250px;
    border-radius: 20px;
    border-bottom: 1px solid #c4c4c480;
    border-right: 1px solid #c4c4c480;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}

.PopProf-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(179.68deg, rgba(255, 255, 255, 0) 29.72%, rgba(34, 45, 54, 30%) 99.72%);
    border-radius: 0 0 20px 20px;
}

.PopProf-absolute-bottom {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: -30px;
    height: 60px;
}

.PopProf-absolute-bottom div {
    background-color: white;
    width: calc(92% - 30px);
    height: 100%;
    border-radius: 14px;
    box-shadow: 7px 7px 16px 0 rgb(0 0 0 / 10%);
    padding-left: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

#PopularProfessionals strong {
    color: #4e575e;
    font-size: 15px;
}

#PopularProfessionals p {
    color: rgba(0, 174, 116, 90%);
    margin-top: 5px;
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
}

@media only screen and (max-width: 1460px) and (min-width: 1260px) {
    #PopularProfessionals ul a:not(:nth-child(n+2)) {
        display: none;
    }
}

@media only screen and (max-width: 1260px) and (min-width: 960px) {
    #PopularProfessionals ul a:not(:nth-child(n+3)) {
        display: none;
    }
}




@media only screen and (max-width: 960px) {
    #PopularProfessionals{
        margin-bottom: -60px;
    }

    #PopularProfessionals ul a {
        height: calc(290px + 60px);
    }

    #PopularProfessionals ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

    #PopularProfessionals li:first-child {
        margin-left: 4vw;
    }

    #PopularProfessionals li {

        margin-right: 26px;

        height: 260px;
        width: 200px;


        align-items: flex-start;
        justify-content: center;
    }

    #PopularProfessionals li:hover {
        background-color: white;
        border-bottom: 1px solid #c4c4c480;
        border-right: 1px solid #c4c4c480;
    }
}