#RegistrationContainer {
    --siders-w: 21%;
    --middle-w: 58%;
    --card-w: 70%
}

#RegistrationContainer {
    width: 100%;
    display: flex;
    padding-top: 36px;
}

.RegC-title {
    width: calc(var(--card-w) + 40px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.RegC-title h2,
.RegC-title h4 {
    color: #4a4a4a;
    text-align: center;
    margin-bottom: 6px;
}

.RegC-title p {
    font-size: .8em;
    color: #4a4a4a;
    margin-top: 0;
}

.RegC-left {
    width: var(--siders-w);
}

.RegC-left {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}

.RegC-left ul {
    height: fit-content;
    width: 100%;
}

.RegC-left li:not(:first-child) {
    margin-top: 10px;
}

.RegC-left li {
    padding: 16px 0;
    border-radius: 0 100px 100px 0;
    color: #3a3a3a;

     
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
}

.reg-li-selected {
    background-color: #009b6730;
    color: var(--green) !important;
}

.RegC-left li span {
    display: flex;
    font-size: 24px;
    margin-right: 10px;
    margin-left: 26px;
}

.RegC-mid {
    margin: 40px 0 80px 0;
    width: var(--middle-w);
    display: flex;
    align-items: center;
    flex-direction: column;
}

.RegC-card {
    margin-top: 30px;
    background-color: #fff;
    box-shadow: rgb(0 0 0 / 8%) 0px 4px 16px 0px, rgb(0 0 0 / 6%) 0px 2px 4px 0px;
    width: var(--card-w);
    padding: 40px;
    border-radius: 20px;
}

#RegCheck {
    width: 80px;
    height: 40px;
    border-radius: 100px;
    background-color: #009b6730;
    color: var(--green);
    font-size: 1.6em;
    margin-top: 18px;
}



.reg-default-buttons {
    width: 40%;
}

.RegC-open-settings {
    display: none !important;
}

.open-side-reg {
    display: none;
}


@media only screen and (max-width: 960px) {
    .r-true {
        display: block;
    }

    .r-false {
        display: none;
    }


    .RegC-left {
        top: 8px;
        left: 0;
        width: 60vw;
        padding: 30px 40px 0 0;
        height: 100vh;
        position: absolute;
        background-color: white;
        z-index: 99;
        box-shadow: 4px 0px 4px #00000030;
    }

    .RegC-mid {
        width: 100%;
        grid-template-columns: auto;
    }

    .reg-default-buttons {
        width: auto;
    }

    .open-side-reg {
        display: flex;
        box-shadow: rgb(0 0 0 / 8%) 0px 4px 16px 0px, rgb(0 0 0 / 6%) 0px 2px 4px 0px;
        position: absolute;
        right: 10vw;
        top: 100px;
        padding: 14px;
        border-radius: 8px;
    }
}