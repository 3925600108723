.SB-li,
.SB-li div {
    display: flex;
    align-items: center;
}

.SB-li {
    justify-content: space-between;
    cursor: pointer;
    height: 56px;
    margin: 0 20px;
    padding: 8px 0;
    background-color: white;
    border-bottom: 1px solid #dfdfdf;

}

.SB-li span {
    width: 40px;
    height: 40px;
    font-size: 1.4em;

     
    display: flex;
    align-items: center;
    justify-content: center;
}