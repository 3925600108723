#Modal {
    position: fixed;
    z-index: 998;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;

    align-items: center;
    justify-content: center;
}

.Modal-closeable {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #00000020;
    z-index: 2;
}

.Modal-white {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    z-index: 3;
    border-radius: 12px;
    position: relative;
}

.Modal-close-btn {
    position: absolute;
    color: black;
    top: 10px;
    right: 20px;
    font-size: 30px;
    cursor: pointer;
}

.Modal-close-btn:after {
    content: "\00d7";
}

@media only screen and (max-width: 960px) {
    #Modal {
        align-items: flex-end;
    }

    .Modal-white {
        height: fit-content !important;
        padding: 48px 0 28% 0 !important;
        width: 100% !important;
        border-radius: 12px 12px 0 0;
    }
}