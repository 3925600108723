.HomeWorks {
    display: grid;
    grid-template-columns: auto auto auto auto;
    margin: 20px 0 0 0;
    justify-content: space-between;
    position: relative;
}

@media only screen and (max-width: 1320px) {
    .HomeWorks {
        grid-template-columns: auto auto auto;
        justify-content: space-around;
    }

    .HomeWorks #Card:nth-child(1n + 4) {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .HomeWorks {
        grid-template-columns: auto auto;
        justify-content: space-around;
    }

    .HomeWorks #Card:nth-child(1n + 3) {
        display: none;
    }
}

@media only screen and (max-width: 560px) {
    .HomeWorks {
        grid-template-columns: auto;
        justify-content: center;
    }

    .HomeWorks #Card:not(:first-child) {
        display: none;
    }
}