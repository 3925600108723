.wrapper-ImagesSlider {
    overflow-x: scroll;
    display: flex;
    width: 100vw;
    padding: 0;
}

#ImagesSlider {
    display: flex;
    position: relative;
    overflow: hidden;
    position: relative;
    z-index: 1;
}

#ImagesSlider {
    border-radius: 7px;
    order: 1;

    min-width: 82%;
    margin-left: 6%;
}

#ImagesSlider:last-child {
    margin-right: 6%;
}

.full-ImagesSlider {
    width: 100vw !important;
    min-width: 100vw !important;
    border-radius: 0 !important;
    margin: 0 !important;
}