#TermsDocx .lst-kix_list_2-6>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_2-7>li:before {
    content: "o  "
}

#TermsDocx ul.lst-kix_list_1-0 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_2-4>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_2-5>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_2-8>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_3-0>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_3-1>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_3-2>li:before {
    content: "\0025aa  "
}

#TermsDocx ul.lst-kix_list_3-7 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-8 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-3 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-1 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_3-5>li:before {
    content: "\0025aa  "
}

#TermsDocx ul.lst-kix_list_1-4 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-2 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-1 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_3-4>li:before {
    content: "o  "
}

#TermsDocx ul.lst-kix_list_1-2 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-0 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-7 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_3-3>li:before {
    content: "\0025cf  "
}

#TermsDocx ul.lst-kix_list_3-5 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-8 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-6 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-5 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-3 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_1-6 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_3-4 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_3-8>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_4-0>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_4-1>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_3-6>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_3-7>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_4-4>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_4-3>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_4-5>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_4-2>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_4-6>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_4-8>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_4-7>li:before {
    content: "o  "
}

#TermsDocx ul.lst-kix_list_4-8 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-6 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-8 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-7 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-0 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-2 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-1 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_1-0>li:before {
    content: "\0025cf  "
}

#TermsDocx ul.lst-kix_list_2-3 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-0 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-1 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-4 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-6 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-5 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_1-1>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_1-2>li:before {
    content: "\0025aa  "
}

#TermsDocx ul.lst-kix_list_2-7 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-2 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-4 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_4-3 {
    list-style-type: none
}

#TermsDocx ul.lst-kix_list_2-5 {
    list-style-type: none
}

#TermsDocx .lst-kix_list_1-3>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_1-4>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_1-7>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_1-5>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_1-6>li:before {
    content: "\0025cf  "
}

#TermsDocx li.li-bullet-0:before {
    margin-left: -18pt;
    white-space: nowrap;
    display: inline-block;
    min-width: 18pt
}

#TermsDocx .lst-kix_list_2-0>li:before {
    content: "\0025cf  "
}

#TermsDocx .lst-kix_list_2-1>li:before {
    content: "o  "
}

#TermsDocx .lst-kix_list_1-8>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_2-2>li:before {
    content: "\0025aa  "
}

#TermsDocx .lst-kix_list_2-3>li:before {
    content: "\0025cf  "
}

#TermsDocx ol {
    margin: 0;
    padding: 0
}

table td,
#TermsDocx table th {
    padding: 0
}

#TermsDocx .c0 {
    color: #595959;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 14pt;
    font-family: "Calibri";
    font-style: normal
}

#TermsDocx .c11 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
}

#TermsDocx .c7 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Calibri";
    font-style: normal
}

#TermsDocx .c14 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c21 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c12 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c33 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.5;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c40 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 2.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c1 {
    padding-top: 18pt;
    padding-bottom: 18pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .c13 {
    color: #565656;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Calibri";
    font-style: normal
}

#TermsDocx .c4 {
    background-color: #ffffff;
    font-size: 10pt;
    font-family: "Roboto";
    color: #595959;
    font-weight: 400
}

#TermsDocx .c18 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 15pt;
    font-family: "Times New Roman";
    font-style: normal
}

#TermsDocx .c38 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Nunito Sans";
    font-style: normal
}

#TermsDocx .c25 {
    text-decoration: none;
    vertical-align: baseline;
    font-size: 18pt;
    font-family: "Roboto";
    font-style: normal
}

#TermsDocx .c17 {
    -webkit-text-decoration-skip: none;
    color: #00b050;
    font-weight: 700;
    text-decoration: underline;
    text-decoration-skip-ink: none
}

#TermsDocx .c31 {
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Arial";
    font-style: normal
}

#TermsDocx .c15 {
    text-decoration: none;
    vertical-align: baseline;
    font-family: "Calibri";
    font-style: normal
}

#TermsDocx .c8 {
    background-color: #ffffff;
    max-width: 425.2pt;
    padding: 70.8pt 85pt 70.8pt 85pt
}

#TermsDocx .c23 {
    color: #000000;
    font-weight: 700;
    font-size: 11pt
}

#TermsDocx .c19 {
    vertical-align: baseline;
    font-family: "Calibri";
    font-style: normal
}

#TermsDocx .c3 {
    color: #595959;
    font-weight: 700
}

#TermsDocx .c30 {
    font-size: 18pt;
    font-family: "Roboto"
}

#TermsDocx .c5 {
    background-color: #ffffff;
    color: #595959
}

#TermsDocx .c20 {
    margin-left: 36pt;
    padding-left: 0pt
}

#TermsDocx .c28 {
    padding: 0;
    margin: 0
}

#TermsDocx .c39 {
    font-size: 26pt;
    font-family: "Arial"
}

#TermsDocx .c10 {
    color: inherit;
    text-decoration: inherit
}

#TermsDocx .c37 {
    font-size: 18pt;
    font-family: "Arial"
}

#TermsDocx .c26 {
    font-weight: 400
}

#TermsDocx .c29 {
    color: #00b050
}

#TermsDocx .c16 {
    height: 11pt
}

#TermsDocx .c35 {
    color: #404040
}

#TermsDocx .c27 {
    font-weight: 700
}

#TermsDocx .c24 {
    color: #000000
}

#TermsDocx .c6 {
    color: #595959
}

#TermsDocx .c32 {
    color: #565656
}

#TermsDocx .c22 {
    font-size: 15pt
}

#TermsDocx .c2 {
    font-size: 14pt
}

#TermsDocx .c36 {
    font-size: 12pt
}

#TermsDocx .c9 {
    background-color: #ffffff
}

#TermsDocx .c34 {
    font-size: 11pt
}

#TermsDocx .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx li {
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

#TermsDocx p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
    font-family: "Calibri"
}

#TermsDocx h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx h4 {
    padding-top: 12pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}

#TermsDocx h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    font-family: "Calibri";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
}