.Hide-popC {
    display: block;
}

#PopularCategories {
    width: 100%;
    display: flex;
    flex-direction: column;
}

#PopularCategories ul {
    display: flex;
    justify-content: space-around;
    width: 100%;
}


#PopularCategories li {
    background-color: white;
    height: 180px;
    width: 180px;
    border-radius: 20px;
    border-bottom: 1px solid #c4c4c480;
    border-right: 1px solid #c4c4c480;
    cursor: pointer;
}

#PopularCategories li span {
    font-size: 56px;
    color: var(--green);
}

#PopularCategories li p {
    font-size: 14px;
    color: #757575;
    margin-top: 14px;
}

#PopularCategories li:hover {
    background-color: var(--green);
    border-bottom: 1px solid #00ae7420;
    border-right: 1px solid #00ae7420;
}

#PopularCategories li:hover p,
#PopularCategories li:hover span {
    color: #fff;
}

@media only screen and (max-width: 1460px) and (min-width: 1260px) {
    #PopularCategories ul a:not(:nth-child(n+2)) {
        display: none;
    }
}

@media only screen and (max-width: 1260px) and (min-width: 960px) {
    #PopularCategories ul a:not(:nth-child(n+3)) {
        display: none;
    }
}

@media only screen and (max-width: 1070px) and (min-width: 960px) {
    #PopularCategories li {
        width: 160px;
        height: 160px;
    }
}


@media only screen and (max-width: 960px) {
    #PopularCategories ul {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;

        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;

        padding-bottom: 16px;
    }

    #PopularCategories {
        margin-bottom: -16px;        
    }

    #PopularCategories li:first-child {
        margin-left: 4vw;
    }

    #PopularCategories li {
        width: 110px;
        height: 146px;
        padding: 0 0 0 30px;
        margin-right: 8px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));


        align-items: flex-start;
        justify-content: center;
    }

    #PopularCategories li:hover {
        background-color: white;
        border-bottom: 1px solid #c4c4c480;
        border-right: 1px solid #c4c4c480;
    }

    #PopularCategories li span {
        margin-bottom: 3px;
        font-size: 42px;
        color: var(--dark);
    }

    #PopularCategories li p {
        font-size: 18px;
        color: var(--dark);
        font-weight: bold;
    }


    #PopularCategories li:hover p,
    #PopularCategories li:hover span {
        color: var(--dark);
    }
}