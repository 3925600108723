.chat-main {
    margin-top: -20px;
    background-color: white;
    border-radius: 18px;
    width: 100%;
}

.Chat-span {
    background-color: #0b0e11;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    color: white;
    font-size: 26px;
    cursor: pointer;
}

.c-hover:hover {
    background-color: var(--hover);
}



.Chat-main-span {
    cursor: pointer;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    padding: 4px;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    color: #0b0e11;
    font-size: 18px;
    box-shadow: 4px 4px 8px #0b0e1120;
}

.c-tomato:hover {
    background-color: tomato;
    color: white;
}

@media only screen and (max-width: 520px) {
    .Chat-span {
        display: none;
    }
}