#ValidationCode {
    width: 380px;
    height: 520px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
}



#ValidationCode span {
    font-weight: bold;
    font-size: 24px;
}

#ValidationCode p {
    font-weight: normal;
}

#ValidationCode button {
    width: 100%;
    border-radius: 8px;
    background-color: var(--green);
    border: 1px solid var(--green);
    height: 50px;
    color: #fff;
    font-size: 16px;
}

#MagicInput {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.MagicI-protect {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: transparent;
    z-index: 2;
    cursor: pointer;
}

.MagicI {
    width: 46px;
    height: 56px;
    border: 2px solid #c4c4c4;
    border-radius: 8px;

    font-size: 20px;
    font-weight: bold;
    color: var(--hover);

    text-align: center;
}

.MagicI:not(:placeholder-shown) {
    border-color: var(--hover);
}

.MagicI:placeholder-shown {
    border-color: #c4c4c4;
}

.ValidC-email {
     
    display: flex;
    align-items: center;
    justify-content: center;

    color: #00000080;
    border: 1px solid #00000060;
    width: fit-content;
    padding: 4px 12px 4px 8px;
    border-radius: 100px;
    font-size: 12px;
}

.ValidC-email div {
     
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    margin-right: 8px;
}

.blink_me {
    animation: blinker 1s linear infinite;
    z-index: 0;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media only screen and (max-width: 960px) {
    #ValidationCode {
        width: 90vw;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .MagicI {
        width: 34px;
        height: 54px;
    }
}