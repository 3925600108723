.CentralMenu {
    min-height: calc(100vh - 170px);
    background-color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.CentralMenu ul {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.CMenuLi {
    width: 80%;
    height: 80px;
    border-radius: 16px;

    text-indent: 30px;

    display: flex;
    justify-content: center;
    flex-direction: column;

    margin-bottom: 30px;
    position: relative;

    border: 1px solid #c4c4c4;
    box-shadow: -2px 2px 6px var(--border);
}

.CMenuLi:hover {

    cursor: pointer;
    border: 1px solid var(--green);

}

.CMenuLi strong {
    font-size: 16px;
    font-weight: bold;
    color: #696969;
}

.CMenuLi p {
    font-size: 12px;
    color: #69696999;
}

.CMenuLi span {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 70px;
    color: #c4c4c4;
}