.reg-avatar-pick-image {
    font-weight: bold;
    cursor: pointer;
    color: #696969
}

.reg-avatar-pick-image button{
    border-radius: 100px;
    background-color: #696969;
    color: white;
    margin-top: 10px;
    padding: 8px 30px;
    
}

.reg-avatar-pick-image:hover {
    color: var(--hover)
}

.reg-avatar-pick-image:hover button{
    background-color: var(--hover)
}

.reg-avatar-remove-image {
    cursor: pointer;
    color: #9b9b9b
}

.reg-avatar-remove-image:hover {
    color: tomato;
}

.reg-avatar-hover-cam {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    cursor: pointer;
}

.reg-avatar-hover-cam-wrap {
    z-index: 4;
    display: flex;
    align-items: flex-end;
    overflow: hidden;

}

.reg-avatar-hover-cam-bottom {
    width: 100%;
    height: 40%;
    background-color: #00000080;
    border-radius: 0 0 30px 30px;
    opacity: 0;
    transition: opacity .5s linear;
}

.reg-avatar-hover-cam:hover .reg-avatar-hover-cam-bottom {
    opacity: 1;
}


@media only screen and (max-width: 960px) {
    .reg-avatar-hover-cam .reg-avatar-hover-cam-bottom {
        opacity: 1;
    }
}