#FindItOut {
    width: 100%;
    height: 480px;
    background-color: #eee;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    --border: 4px solid #eee
}

#FindItOut strong {
    position: absolute;
    z-index: 2;
    color: white;
    bottom: 20px;
    left: 20px;
}

#FindItOut aside {
    cursor: pointer;
    transition: all .5s;

    width: 100%;
    height: 100%;

    background-color: #c4c4c4;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    z-index: 0;
}

#FindItOut aside:hover {
    transform: scale(1.2);
}

#FindItOut div {
    position: relative;
    overflow: hidden;
    position: relative;
}

.fio-quarter {
    width: 25%;
    height: 100%;

}

.fio-full {
    height: 100%;
}

.fio-b-left {
    border-left: var(--border);
}

.fio-b-right {
    border-right: var(--border);
}

.fio-b-top {
    border-top: var(--border);
}

.fio-smaller {
    height: 40%;
    width: 100%;
}

.fio-bigger {
    height: 60%;
    width: 100%;
}

.fio-gradient {
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(179.68deg, rgba(255, 255, 255, 0) 29.72%, #222D36 99.72%);
    z-index: 1;
}

@media only screen and (max-width: 960px) {
    #FindItOut aside:hover {
        transform: none;
    }

    #FindItOut {
        width: 92%;
        margin-left: 4%;
        justify-content: space-between;
    }

    .fio-quarter {
        width: 50%
    }

    .fio-full {
        display: none;
    }
}