#Stripe {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
}

#Stripe div {
    width: 18%;
    display: flex;
}

#Stripe span {
    height: 100%;
    font-size: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--green);

}

#Stripe aside {
    margin-left: 16px;
}

#Stripe p,
#Stripe h4 {
    margin: 0;
    color: #222D36;
}

#Stripe h4 {
    margin-bottom: 6px;
}

#Stripe div:not(:last-child) {
    border-right: 1px solid #c4c4c4
}

@media only screen and (max-width: 960px) {
    #Stripe {
        display: none;
    }
}