.WindowAlert {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 999 !important;
    opacity: 1;
}

.wa-scroll {
    animation-name: windowAlert;
    animation-duration: .8s;
}

@keyframes windowAlert {
    from {
        top: -172px;
        opacity: .5;
    }

    to {
        transform: translate(0, 0px);
        opacity: 1;
    }
}

.wa-shake {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0px) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

.wa-box {
    margin-top: 10px;
    background-color: var(--dark);
    width: 360px;
    max-width: calc(90vw - 80px);
    padding: 20px 40px;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(105, 105, 105, 0.2);
    border: 1px solid #696969;
}


.wa-box-text {
    color: #eee;
    width: 100%;
}

.wa-box-btns {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.wa-btns {
    padding: 12px 22px;
    border-radius: 8px;
    font-weight: bold;
    color: #eee;
}

.wa-box-btn-okay {
    background-color: var(--green);
    border: 2px solid var(--green);
    margin: 0 0 0 20px;
}

.wa-btn-white {
    background-color: white;
    border: 2px solid white;
    color: var(--dark);
}

.wa-box-btn-cancel {

    border: 2px solid #eee;

}

@media only screen and (max-width: 960px) {
    .WindowAlert {

        z-index: 999 !important;
    }
}