#SearchContainer {
    width: 100%;
    min-height: 100vh;
    display: flex;
}

.SerC-left form {
    left: 0;
    top: 56px;
    position: fixed;
    height: 100vh;
    width: 220px;
    background-color: white;
}

.filter-border {
    box-shadow:
        0 1px 2px 0 rgb(60 64 67 / 30%),
        0 2px 6px 2px rgb(60 64 67 / 15%);
    margin-bottom: 40px;
    border: 1px solid #DEE6EB;
}

.SerC-left li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.SerC-left li strong {
    width: 80%;
}

.SerC-left li:not(:first-child) {
    border-top: 1px solid #DEE6EB;
}

.SerC-mid-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 60px 0px;

    justify-content: space-between;
}

.Ser-spinner-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
}

#breadcrumbs {
    position: absolute;
    top: -60px;
    left: 4px;
    display: flex;
    color: #75757599;
}


.Serc-sugg {
    font-size: 20px;
    margin-bottom: 40px;
    width: 100%;
    text-align: center;
    color: #757575;
}


.ser-cats h5 {
    color: #757575;
    margin: 10px 0 0 1px;
}

.ser-cats h5:first-child {
    margin-top: 30px;
}

#SearchContainer {
    --left: 250px;
    --margin: 10px;
    --right: 220px;
    --mid: calc(100vw - 480px);
}

.SerC-left {
    width: var(--left);
    margin-right: var(--margin);



    display: flex;
    justify-content: center;
}

.SerC-mid {
    width: var(--mid);



    margin: 50px 0 80px 0;
    position: relative;
}


.SerC-right {
    width: var(--right);
}

.SerC-filters-wrapper {
    display: none;
}


@media only screen and (min-width: 1380px) {
    .SerC-mid {
        max-width: 70vw;
    }
}

@media only screen and (max-width: 1380px) {
    #SearchContainer {
        --left: 230px;
        --margin: 40px;
        --right: 20px;
        --mid: calc(100vw - 340px);
    }
}

@media only screen and (max-width: 1160px) and (min-width: 960px) {
    #SearchContainer {
        --mid: calc(100vw - 250px);
        --left: 230px;
        margin-right: 20px;
    }

    .SerC-right {
        display: none;
    }
}

@media only screen and (max-width: 1090px) and (min-width: 960px) {
    #SearchContainer {
        --mid: calc(100vw - 280px);
        --left: 260px;
        margin-right: 20px;
    }

    .SerC-right {
        display: none;
    }

    .SerC-mid-grid {
        grid-template-columns: auto auto;
        justify-content: space-around;
    }
}

@media only screen and (max-width: 960px) {
    .SerC-filters-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100vw;
        position: fixed;
        top: 58px;
        left: 0;
        z-index: 99;
        box-shadow: 4px 0px 8px #151a1f20;
    }

    .SerC-filters {
        border: 1px solid #eee;
        padding: 10px 0;
        display: flex;
        background-color: white;
        height: 26px;
        width: 100%;
        font-weight: bold;
        color: #757575;
        justify-content: space-between;
    }

    .SerC-mid-grid {
        justify-content: space-around;
    }

    .SerC-left,
    .SerC-right {
        display: none;
    }

    .SerC-mid {
        width: 100%;
    }




    #breadcrumbs {
        display: none;
    }

    .Serc-ops {
        padding-left: 45px;
    }


    .showLeft {
        position: fixed;
        display: flex;
        z-index: 101;
        width: 100vw;
        background-color: white;
        overflow-y: scroll;
        top: 0;
        height: 100vh;
    }

    .showLeft-header {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 40px);
        height: 70px;
        border-bottom: 1px solid #DEE6EB;
        z-index: 99;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 20px;
        color: #151a1f;
    }

    .showLeft-header span {
        font-size: 30px;
    }

    .showLeft-header button {
        background-color: var(--hover);
        color: white;
        height: 42px;
        border-radius: 100px;
        padding: 0 50px;
        font-weight: bold;
        font-size: 14px;
        width: 42%;
    }

    .showLeft-header--bottom {
        position: fixed;
        top: auto;
        bottom: 0;
        background-color: white;
        border-top: 1px solid #DEE6EB;
    }

    .showLeft form {
        box-shadow: 0 0 0 transparent !important;
        border: 0px solid transparent !important;
    }

    .showLeft form ul li,
    .showLeft form ul {
        width: 100% !important;
    }
}

@media only screen and (max-width: 960px) {

    .SerC-left form {
        position: relative;
        width: 100%;
        top: 80px;
    }

    .SerC-to-hide {
        display: none !important;
    }
}

@media only screen and (max-width: 860px) {
    .SerC-mid-grid {
        grid-template-columns: auto auto;
    }
}

@media only screen and (max-width: 560px) {
    .SerC-mid-grid {
        grid-template-columns: auto;
        justify-content: center;
    }
}

@media only screen and (max-width: 500px) {
    #SearchContainer {
        margin-top: -41px;
    }
}