#DownApp {
    margin-bottom: 100px;
    justify-content: space-between;
    padding-top: 120px;
    margin-bottom: -60px;
}

#DownApp h2 {
    font-size: 40px;
    font-family: 'Montserrat', sans-serif;
}

#DownApp span {
    font-weight: 100;
    font-size: 34px;
}

#DownApp h2,
#DownApp span {
    margin: 0;
    color: #222D36;
}

.down-middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-left: 60px;
}

.down-tablet {
    height: 60vh
}

@media only screen and (max-width: 1160px) {
    #DownApp {
        flex-direction: column;
        text-align: center;
    }

    .down-middle {
        margin-left: 0px;
    }

    #DownApp h2 {
        margin-top: 40px;
    }
}

@media only screen and (max-width: 960px) {
    #DownApp h2 {
        font-size: 34px;
    }
    
    #DownApp span {
        font-size: 28px;
    }

    .down-tablet {
        height: 50vh
    }

    #DownApp {
        width: 94vw;
        margin-left: 3vw;
        margin-bottom: auto;
    }
}

@media only screen and (max-width: 760px) {
    .down-store {
        width: 39vw;
    }
}