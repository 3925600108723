.soc-nav-btn {
    background-color: white;
    color: var(--dark);
    border-radius: 8px;
    height: 48px;
    width: 42%;
    border: 1px solid transparent;
}

.soc-nav-btn:hover {
    background-color: var(--border);
    border: 1px solid var(--border);
}

.soc-nav-btn--selected {
    border: 1px solid var(--dark);
}

.soc-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.soc-budgets-b-top {
    border-top: 1px solid var(--border);
    width: 100%;
}

.orders-list-empty-w {
    padding-bottom: 70px;
    color: var(--dark);
    width: 450px;
    text-align: center;
}

@media only screen and (max-width: 960px) {
    .soc-nav-btn:hover {
        background-color: inherit;
        border: inherit;
    }

    .orders-list-empty-w {
        width: 80%;
    }
}