.SubHeader {
    width: 100%;
    height: var(--subheader-height);
    background-color: #fff;
    display: flex;
    justify-content: center;
}

.sh-center {
    width: 90%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
}

.sh-c-left {
    height: 100%;
    width: 20%;
}

.sh-buttons {
    height: 100%;
    background-color: transparent;

     
    display: flex;
    justify-content: center;
    align-items: center;
}

.sh-icons-wrap {
     
    display: flex;
    justify-content: center;
    align-items: center;
}

.sh-icon-location {
    font-size: 18px;
    margin-right: 8px;
    margin-left: -3px;
}

.sh-icon-list {
    font-size: 22px;
    margin-right: 8px;
}

.sh-buttons:hover {
    color: var(--green);
}

.sh-button-allc {
    font-weight: bolder;
}

.sh-button-location {
    color: #25262C;
    padding: 0;
}

.sh-icon-forward {
    margin-left: 8px;
}

.sh-c-left {
    display: none;
}

.sh-c-mid {
    height: 100%;
    width: 100%;

     
    display: flex;
    align-items: center;
}

.sh-cats {
    display: flex;
}

.sh-visible {
    display: none;
}

.sh-cats-list li {
    cursor: pointer;
    padding: 0 26px;
    position: relative;
    height: 34px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.sh-cats-list li h5 {
    font-weight: 400;
    margin: 0;
    color: #757575;
}

.sh-cats-list li span {
    position: absolute;
    width: 80%;
    left: 10%;
    bottom: 0;
    height: 3px;
    background-color: var(--green);
    border-radius: 2px 2px 0 0;
    display: none;
}

.sh-cats-list li:hover h5 {
    color: var(--green);
}

.sh-cats-list li:hover span {
    display: flex;
}

@media only screen and (max-width: 1110px) {
    .sh-cats-list a:nth-child(2) {
        display: none;
    }
}

@media only screen and (max-width: 960px) {
    .SubHeader {
        background-color: var(--green);
        align-items: flex-start;
        padding-bottom: 6px;
    }

    .sh-cats-list {
        display: none;
    }

    .sh-c-mid,
    .sh-c-left {
        width: 50%;
        display: flex;
    }

    .sh-c-mid {
        justify-content: flex-end;
    }

    .sh-cats {
        display: none;
    }

    .sh-visible {
        display: flex;
    }

    .sh-buttons {
        color: #fff;
    }

    .sh-icons-wrap,
    .sh-buttons,
    .sh-c-left,
    .sh-c-mid {
        margin: 0;
        padding: 0;
    }

    .sh-icon-location {
        margin: 0 8px 0 0;
    }

    .sh-icon-list {
        margin-right: 4px;
    }

    .sh-icon-forward {
        display: none;
    }

    .sh-buttons:hover {
        color: #fff;
    }
}