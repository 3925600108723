#Sign {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

#Sign h2 {
    color: var(--green);
}

#Sign form {
    width: 36%;
    display: flex;
    flex-direction: column;
}

.Sign-Policy {
    max-width: 580px;
    line-height: 1.4;
    text-align: center;
    color: #696969;
}

.Sign-Policy span {
    text-decoration: underline;
    font-weight: 500;
}

@media only screen and (max-width: 960px) {
    #Sign form {
        width: 88%;
    }

    .Sign-Policy {
        width: 80%;
    }
}