.SRP-btn {
    color: #696969;
    cursor: pointer;
    text-decoration: underline;
}

.SRP-text {
    color: #00000099;
}

.SRP-stron {
    font-size: 1.6em;
}