#alert {
    -webkit-animation: seconds 1.0s forwards;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 5s;
    animation: seconds 1.0s forwards;
    animation-iteration-count: 1;
    animation-delay: 2s;
    position: fixed;
    z-index: 9;
    padding: 20px;
    background-color: #f44336;
    color: #fff;
    width: 50vw;
    bottom: 40px;
    left: 25vw;
    border-radius: 4px;

}

@media only screen and (max-width: 960px) {
    #alert {
        width: 80vw;
        left: 5vw;
    }
}