.mercad-pago-pgp {
    display: flex;
    justify-content: flex-end;
}

.mercad-pago-pgp p {
    padding: 0 2px;
}

@media only screen and (max-width: 960px) {
    .mercad-pago-pgp {
        justify-content: center;
    }
}