.chat-container {
    display: flex;
}

#Chatting {
    width: 100%;
    height: calc(90vh - 82px);
}

#ChatContacts {
    height: calc(100vh - 122px);
}

#ChatApp {
    height: 100%;
    width: 100%;
}

.chatC-list-wrapper {
    display: flex;
    justify-content: flex-end;
}

.chatC-list {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: overlay;
    border-radius: 18px;
}

.chatC-li--c {
    border-bottom: 1px solid #66778120;
}

.chatC-header {
    height: 70px;
    width: 100%;

    color: #667781;
    display: flex;
    align-items: center;
}

.chatC-header strong {
    font-size: 20px;
    font-weight: 500;
    margin-left: 28px;
}

.chatC-li {
    width: 100%;
    display: flex;

}

.chatC-li--c {
    height: 80px;
}

.chatC-li--a {
    height: 70px;
}

.chatC-li--c:hover {
    background-color: #66778130;
    cursor: pointer;
}

.chatC-li-avatar--c {
    width: 90px;
    height: 90px;
}

.chatC-li-avatar--c div {
    width: 58%;
    height: 58%;
}

.chatC-li-avatar--a {
    width: 70px;
    height: 70px;
    margin-right: -14px;
}

.chatC-li-avatar--a div {
    width: 60%;
    height: 60%;
}

.chatC-li-infos {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
}

.chatC-li-infos--c {
    width: calc(100% - 80px);
}

.chatC-li-infos h2,
.chatC-li-infos p {
    margin: 0;
}

.chatC-li-infos h2 {
    color: #181f36;
    font-weight: 500;
    max-width: 136px;
}

.chatC-li-infos--c h2 {
    font-size: 15px;
    margin-bottom: 3px;
}

.chatC-li-infos--a h2 {
    font-size: 13px;
}

.chatC-li-infos--c p {
    color: #667781;
    font-weight: 300;
}

.chatC-li-infos h5 {
    color: #667781;
    font-weight: 500;
    margin: 0;
}

.chatC-li-infos p {
    font-size: 11px;
}

.chatC-li-infos--c h5 {
    font-size: 13px;
}

.chatC-li-infos--a p {
    display: none;
}

.chatC-li-infos--a strong {
    font-size: 11px;
}


.chatC-list::-webkit-scrollbar,
.chatA-main::-webkit-scrollbar {
    width: 0px;
}

.chatC-list::-webkit-scrollbar-track,
.chatA-main::-webkit-scrollbar-track {
    background: transparent;
}

.chatC-list::-webkit-scrollbar-thumb,
.chatA-main::-webkit-scrollbar-thumb {
    background: transparent;
}

.chatC-list::-webkit-scrollbar-thumb:hover,
.chatA-main::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

.chatA-header {
    width: 100%;
    height: 70px;
    position: relative;
}

.chatA-middle {
    width: 100%;
    position: relative;
}

.chatA-footer {
    width: 100%;
    height: 60px;
}

.fix-chatA-footer-in-modal {
    position: absolute;
    bottom: 10px;
    left: 0;
    z-index: 1;
}

.chatA-main {
    width: 100%;
    height: 100%;
    overflow: overlay;
    box-shadow: 0px 0 40px #66778120 inset;
    border-radius: 18px 18px 0 0;
    z-index: 1;

}

.chatA-main li:first-child {
    padding-top: 20px;
}

.chatA-main li:last-child {
    padding-bottom: 40px;
}


.chat-bubble {
    padding: 0;
    display: flex;
    flex-direction: column;

    width: 100%;
}

.chat-author-bubble {
    align-items: flex-end;

}

.chat-bubble span {

    width: fit-content;
    color: #222d36;
    line-height: 1.6;
    font-size: 17px;

    border-radius: 13px;

    min-width: 30px;
    max-width: 84%;

    padding: 14px;
    margin: 0 0 4px 0;

    word-break: break-all;
}

.chat-bubble span p {
    padding: 0;
    margin: 0;
    font-size: .8em;
}

.chat-author-bubble span {
    background-color: #f1f1f1;
    margin-right: 20px;
}

.chat-receiver-bubble span {
    background-color: var(--border);
    margin-left: 10px;
}



.chat-back {
    position: absolute;
    left: 20px;
    padding: 8px;
    color: #181f36;
    font-size: 16px;
    cursor: pointer;

    margin-top: 6px;
}

.chat-back:hover {
    color: #181f3699;
}

.chatA-footer form {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.chatA-footer form input {
    height: 42px;
    border-radius: 18px;
    background-color: var(--border);
    text-indent: 20px;
    font-weight: 600;
    color: #181f36;
    width: calc(100% - 40px);
    margin-left: 20px;
}

.chatA-footer form button {
    color: white;
    font-size: 14px;
    background-color: var(--green);
    width: 52px;
    height: 42px;
    border-radius: 18px;
    position: absolute;
    margin-right: 16px;
    right: 0;
    box-shadow: 0px 4px 8px var(--hover);
}

.chatA-footer form button:hover {
    color: rgba(24, 31, 54, .9);
}

.chat-inBox {

    width: 24px;
    height: 24px;

    color: white;
    font-size: 12px;
    font-weight: bold;

    background-color: #cb2f37;
    border-radius: 8px;

}

.chatC-li-texts--c {
    max-width: 180px
}

.chatC-li-texts--a {
    padding-left: 14px;
    max-width: 140px
}

@media only screen and (max-width: 960px) {
    #Chatting {
        height: calc(100vh - 82px);
    }
}

@media only screen and (max-width: 520px) {
    .chatA-middle {
        height: calc(100% - 130px);
    }

    .chatA-main {
        max-height: 100vh;
        height: 100%;
    }

    .chatC-list-wrapper {
        width: calc(100vw - 20px);
        height: calc(80vh - 10px);
    }
}