#MiniBanners {
    display: flex;
    justify-content: space-between;
}

#MiniBanners a {
    width: 49%;
    transition: all .2s ease-in-out;
    cursor: pointer;

}

#MiniBanners img {
    width: 100%;
}

#MiniBanners img:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 960px) {
    #MiniBanners {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        margin: 0;
        padding: 0;
    }

    #MiniBanners a {
        width: 94%;
        margin-left: 4%;
    }

    #MiniBanners img {
        max-height: 410px;
        width: 100%;
        height: fit-content;
        margin: 0 !important;
        padding: 0;
        position: relative;
    }

    #MiniBanners img:hover {
        transform: scale(1);
    }
}

@media only screen and (max-width: 560px) {
    #MiniBanners img {
        max-height: 210px;
    }
}