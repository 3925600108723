#HomeContainer {
    width: 91%;
    max-width: 1600px;
    padding: 40px 0;
}

.HomeTitle {
    width: 100%;
    margin: 100px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.mrg-0 {
    margin: 60px 0 0 0 !important;
}

.HomeTitle h2 {
    font-size: 40px;
    color: #222D36;
}

.HomeTitle p {
    margin-top: -14px;
    font-size: 20px;
    color: #222D3690;
}

@media only screen and (max-width: 960px) {
    #HomeContainer {
        padding: 0;
        width: 100%;
    }

    .HomeTitle {       
        margin: 50px 0 0 0;
        align-items: flex-start;
    }
    .mrg-0 {
        margin: 40px 0 0 0 !important;
    }
    .HomeTitle h2 {

        display: none;
    }
    
    .HomeTitle p {
        padding-left: 6%;
        margin-top: 10px;
        font-size: 15px;
        color: #222D3650;
        font-weight: bold;
    }
}