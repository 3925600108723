#CategoriesSlider {
    
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
}

.CatS-square {
    cursor: pointer;

    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: white;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.08));
    border-radius: 14px;
    padding: 10px;

}

.CatS-square-wrap {
    margin: 10px 0 0 12px;
    width: 100%;
}

.CatS-square p {
    color: #4e575e;
    margin: 6px 0 3px 0;
    font-weight: 600;

    display: flex;
}

.CatS-circle-gradient {
    position: absolute;
    width: 100%;
    height: 100%;    
    background: linear-gradient(219.68deg, rgba(255, 255, 255, 0) 29.72%, rgba(34, 45, 54, 50%) 80.72%, rgba(34, 45, 54, 70%) 100%);    
    top: 0;
    left: 0;
}

.CatS-X {
    width: 100%;
    height: 100%;
}

.CatS-circle {
    position: relative;
    overflow: hidden;
    border-radius: 12px;
    width: 190px;
    height: 190px;
    color: var(--dark);
    font-size: 40px;
}

#CategoriesSlider::-webkit-scrollbar {
    width: 0px;
}

#CategoriesSlider::-webkit-scrollbar-track {
    background: transparent;
}

#CategoriesSlider::-webkit-scrollbar-thumb {
    background: transparent;
}

#CategoriesSlider::-webkit-scrollbar-thumb:hover {
    background: transparent;
}

@media only screen and (max-width: 1500px) {
    .CatS-square:last-child {
       display: none;
    }

    .CatS-circle {
        width: 210px;
        height: 210px;
    }
}

@media only screen and (max-width: 1350px) {
    .CatS-circle {
        width: 190px;
        height: 190px;
    }
}

@media only screen and (max-width: 1200px) {

    .CatS-square:nth-child(5) {
        display: none;
     }
     
    .CatS-circle {
        width: 210px;
        height: 210px;
    }
}

@media only screen and (max-width: 1050px) {
    .CatS-circle {
        width: 190px;
        height: 190px;
    }
}


@media only screen and (max-width: 960px) {
    .CatS-square {
        display: flex !important;
     }

    .CatS-circle {
        width: 140px;
        height: 140px;
        font-size: 30px;
    }

    #CategoriesSlider {
        width: 96%;
        padding-left: 4%;
    }

    .CatS-square:not(:first-child) {
        margin-left: 15px;
    }

    .CatS-square:last-child {
        margin-right: 15px;
    }

    #CategoriesSlider {
        margin: 0 0 -20px 0 !important;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
    }

}