#Card {
    height: fit-content;
    box-shadow: 7px 7px 16px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

#CardDouble {
    box-shadow: 7px 7px 16px 0 rgb(0 0 0 / 10%);
    border-radius: 8px;
    background-color: #fff;
    position: relative;
    overflow: hidden;
}

#Card:hover,
#CardDouble:hover {
    box-shadow: 0 7px 30px 0 rgb(0 0 0 / 15%), 0 2px 6px 0 rgb(0 0 0 / 10%);
}

.Card-top,
.Card-bottom {
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Card-top {
    height: 274px;
}

.Card-bottom {
    --bottom-width: 86%;
}

.Card-bottom {
    min-height: 174px;
    display: flex;
    align-items: flex-start;
}

.Card-bottom div {
    width: var(--bottom-width);
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#Card h1,
#Card h6,
#Card h2,
#Card p {
    margin: 0;
}

.Card-bottom h1 {
    color: #222D36;
    font-size: 17.5px;
    padding-top: 4px;
}

#Card h2 {
    color: #585858;
    font-size: 13px !important;
    text-align: center;
}

.Card-bottom p {
    color: rgba(117, 117, 117, 74%);
    font-size: 14px;
}

.Card-user p {
    color: #75757599;
    padding: 14px 0 4px 0;
    font-weight: bold;
    font-size: 12px;
}

.Card-bottom h6 {
    font-size: 22px;
    padding-bottom: 4px;
}

.card-b-desc {
    margin: 0 0 0 !important;
    min-height: 50px;
}

.card-b-end {
    position: absolute;
    display: flex;
    bottom: 18px;
    background-color: rgb(236, 236, 236);
    border-radius: 8px;
    padding: 2px 0;
}

.card-b-user-name {
    color: #64646499 !important;
    font-weight: bold;
    height: fit-content;
    white-space: nowrap;
    text-align: start;
    font-size: 10px;
}

#Card-switch-btn {
    background-color: #fff;
    width: 36px;
    height: 36px;
    box-shadow: 4px 4px 4px #00000020;
    border-radius: 8px;
    z-index: 3;

    position: absolute;
    top: 24px;
    right: 22px;
}

#Card-switch-btn:hover {
    background-color: var(--dark);
    color: #fff;
}

#Card-contact-btn {
    position: absolute;
    background-color: #222D36;
    bottom: -40px;
    width: var(--bottom-width);
    height: 42px;
    border-radius: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-shadow: 1px 1px 2px #00000020;
    opacity: 0;
    transition: opacity .4s ease-in-out, bottom 0.2s linear;
    z-index: 2;
}

#Card:hover .Card-bottom button,
#CardDouble:hover aside {
    bottom: 17px;
    zoom: 1;
    filter: alpha(opacity=50);
    opacity: 1;
}

#Card-contact-btn-edit {
    position: absolute;
    border: 2px solid#222D36;
    background-color: white;
    width: var(--bottom-width);
    height: 38px;
    border-radius: 10px;
    color: #222D36;
    font-weight: bold;
    font-size: 14px;
    transition: opacity .4s ease-in-out, bottom 0.2s linear;
    z-index: 2;
    bottom: 17px;
}

.Card-banner {
    width: 89%;
    height: 88%;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    text-align: center;
    display: flex;
    flex-direction: column;

}

.Card-banner-img {
    height: 100%;
    width: 100%;
    background-color: #cccccc;
    color: #646464;
    font-size: 40px;
}

.Card-banner-bottom-location {
    box-shadow: 0px 0px 6px #222D3680;
    width: 88%;
    height: 44px;
    position: absolute;
    bottom: 10px;
    left: 6%;
    z-index: 2;
    background-color: white;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.Card-user {
    width: 90%;
    height: 88%;

    box-shadow: inset 0px 0px 10px #222D3660;
    border-radius: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

}

.Card-user-img {
    width: 130px;
    height: 130px;
    border-radius: 8px;
    border: 1px solid #c4c4c420;
    position: relative;
}

.card-priceWdiscount {
    max-width: 100%;
    margin: 2px 0 -2px 0;
    display: flex;
    align-items: center;
}

.card-priceWdiscount p {
    padding-left: 10px;
    text-decoration: line-through;
    color: #00000040;
    font-weight: 600;
}

.card-priceWdiscount h6 {
    background-clip: text;
    background-color: var(--green);
    color: transparent;
    font-size: 20px;
}

.PreDefined {
    width: 300px;
}

.InSearch {
    width: 21vw;
}

@media only screen and (max-width: 1040px) and (min-width: 960px) {
    .PreDefined {
        width: 280px;
    }
}

@media only screen and (max-width: 1380px) {
    .InSearch {
        width: 23vw;
    }
}

.card-top-mobile {
    padding-left: 16px;
}

.card-top-mobile h6,
.card-top-mobile h4 {
    margin: 0px;
}

.card-top-mobile h4 {
    color: #757575;

    font-size: 12px;

}

.card-top-mobile h6 {
    color: #75757599;
    padding-bottom: 3px;
    font-size: 10px;
}

@media only screen and (max-width: 1160px) and (min-width: 1090px) {
    .InSearch {
        width: 25vw;
    }
}

@media only screen and (max-width: 1090px) {
    .InSearch {
        width: 30vw;
    }
}

@media only screen and (max-width: 960px) {
    #Card:hover .Card-bottom button {
        display: none;
    }

    #Card-switch-btn {
        width: 46px;
        height: 46px;
    }

    #Card-switch-btn:hover {
        background-color: inherit;
        color: inherit;
    }

    .card-b-desc {
        margin: 8px 0 !important;
    }

    .card-priceWdiscount {
        margin: -7px 0 -2px 0;
    }
}

@media only screen and (max-width: 860px) {
    #Card {
        width: 40vw;
    }
}

@media only screen and (max-width: 760px) {
    #Card {
        width: 44vw;
    }
}

@media only screen and (max-width: 560px) {
    #Card {
        width: 80vw;
    }
}

@media only screen and (max-width: 500px) {
    .Card-top {
        height: fit-content;
    }

    .Card-bottom {
        min-height: 184px;
        padding-top: 20px;
    }

    .Card-bottom h1 {
        color: #222D36;
        font-size: 21.5px;
        padding-top: 4px;
        margin-bottom: -8px !important;
    }

    #Card {
        width: 96vw !important;
        padding: 0 3vw;
        border-radius: 0 !important;
    }

    #Card-switch-btn {
        right: 42px;
    }

    #Card {
        width: 90vw;
    }
}