.search-titles {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 10px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    
}


.search-titles h2 {
    font-size: 24px;
    color: #222D36;
}

.search-titles p {
    margin-top: -14px;
    font-size: 16px;
    color: #222D3690;
}
