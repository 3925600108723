#WorkCreate {
    background-color: white;
    width: 60%;
    left: 20%;
    z-index: 3;
    position: absolute;
    border-radius: 12px;
    box-shadow: 0px 2px 6px #00000020;
}

#WorkCreate h2 {
    padding-top: 40px;
    font-size: 2em;
    color: #4a4a4a;
    text-align: center;
}

#WorkCreate form {

    display: flex;
    flex-direction: column;
}

.as {
    height: 44px;
    color: #696969;
    font-weight: 500;
}

.wc-two {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.wc-btn-psition {
    position: relative;
    width: fit-content;
}


.hover-green:hover {
    background-color: var(--green) !important;
}

.done-button {
    position: fixed;
    bottom: 0;
    background-color: white;
    padding: 14px 0;
    width: 500px;
    right: 20%;
    border-radius: 12px 12px 0 0;
    box-shadow: 0 -4px 14px 0 rgb(0 0 0 / 8%);
    z-index: 10;
}

.wc-subworks {
    width: 560px;
}

.wc-subworks-ul {
    margin-top: 20px;
    display: flex;
    width: 100%;
    max-width: 100%;

    padding-bottom: 15px;
    margin-bottom: -15px;

    overflow-x: scroll;
}

.wc-subworks-li {
    color: var(--dark);
    padding: 24px 40px;
    margin-right: 20px;
    border: 1px solid #c9c9c9;
    white-space: nowrap;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.wc-subworks-modal {
    width: 560px;
    display: flex;
    flex-direction: column;
}

.wc-add-subworks {
    width: 100%;
    height: 48px;
    background: var(--hover);
    color: var(--dark);
    border-radius: 8px;

}

.wc-add-subworks:hover {
    background: var(--green);
    color: white;
}

@media only screen and (max-width: 960px) {
    .wc-add-subworks:hover {
        background: inherit;
        color: inherit;
    }

    .wc-subworks-modal {
        width: 90%;
    }

    .wc-subworks {
        width: 100%;
    }

    .done-button {
        border-radius: 0;
        right: 0;
        width: 100vw;
    }

    #WorkCreate {
        width: 100%;
        left: 0%;
        top: 58px;
        border-radius: 0;

    }

    #WorkCreate form {
        width: 92%;
    }

    #WorkCreate h2 {
        font-size: 26px
    }
}