body {
  margin: 0;
  padding: 0;
}

.Wrap-all-EasyCrop {
  width: 600px;
  height: 600px;
  position: relative;
  background-color: #fff;
}

.EasyCrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 20%), 0 -1px 2px 0 rgb(0 0 0 / 20%);
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  width: 50%;
  transform: translateX(-50%);
  height: 40px;
  display: flex;
  align-items: center;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
  background: #00ae74;
  width: 100%;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #00ae74;
  background: #00ae74;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #00ae74;
  background: #00ae74;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type='range']::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(0, 174, 116, 0.16);
  border-radius: 50%;
}

.controls:hover input[type='range']::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(0, 174, 116, 0.16);
}


.crop-buttons {
  position: absolute;
  width: 600px;
  background-color: #fff;
  height: 70px;
  top: -540px;
  left: -150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.crop-buttons span {
  height: 40px;
  font-size: 1.6em;
  color: #222a33;
  width: 40px;
  margin-left: 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 100px;
}

.crop-buttons span:hover {
  background-color: #e9e9e9;
}

.crop-buttons-button {
  margin-right: 20px;
}

.crop-buttons-button button {
  height: 38px;
  background-color: #222a33;
  color: #e9e9e9;
  padding: 0 34px;

  border: 0px solid transparent;
  border-radius: 100px;
}


@media only screen and (max-width: 960px) {
  .Wrap-all-EasyCrop {
    width: 100%;
    height: 300px;
  }

  .EasyCrop {
    box-shadow: 0 0 0 transparent;
  }

  .controls {
    width: 80%;
  }

  .crop-buttons {
    width: 100vw;
    top: -240px;
    left: -10vw;
    background-color: transparent;
  }

  .crop-buttons span {
    background: #c4c4c4;
  }


}

@media only screen and (max-width: 760px) {
  .crop-buttons {
    background-color: white;
    top: -310px;
    border-radius: 12px 12px 0 0;
  }

  .crop-buttons span {
    background: transparent;
  }
}