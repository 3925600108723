#Footer {
    width: 100%;
    background-color: #0b0e11;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.f {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.f--contatos {
    flex-direction: column;
}

.conts--one, .conts--three {
    color: #737271;
}
.conts--two {
    color: rgb(150, 150, 150);
    font-size: 1.3em;
}
.conts--two a,
.conts--two a:link,
.conts--two a:visited,
.conts--two a:hover,
.conts--two a:active {
    color: rgb(199, 199, 199);
}

.f--logo {
    color: #fff;
    display: flex;
    font-size: 7em;
    margin-top: 40px;
}

.f--logo img {
    width: 80px
}
.f--redes {
    width: 220px;
    justify-content: space-around;
    border-radius: 6px;
    border: 1px solid rgb(199, 199, 199);
    padding: 14px 40px;
}
.f--redes span {
    display: flex;
    color: rgb(199, 199, 199);
    font-size: 1.3em;
}
.f--redes span:hover {
    color: var(--hover);
}
.f--redes a {
    cursor: pointer;
    display: flex;
}
.f--redes a,
.f--redes a:link,
.f--redes a:visited,
.f--redes a:hover,
.f--redes a:active {
    color: #fff;
}

.f--copyRight {
    margin-top: -10px;
    text-align: center;
    color: #6c6b6a;
}

#Footer h2 {
    margin-top: -8px;
    font-family: 'Montserrat', sans-serif;
    color: white;
}

.footer-central {
    width: 100%;
    background-color: #0b0e11;
    padding: 16px 0;
    color: white;
    border-top: 1px solid #222d36;
    border-bottom: 1px solid #222d36;
}

.footer-central:hover {
    color: var(--hover);
}

.footer-central:hover .f-central-hvr{
    background-color: var(--green);
}

.f-central-hvr {
    background-color: #c4c4c415;
    height: 30px;
    width: 30px;
    border-radius: 8px;
    margin-left: 10px;
}

.footer-central-3 {
    padding: 12px 22px 12px 10px;
    color: white;
    display: flex;
    align-items: center;
    border: 2px solid #232528;
    border-radius: 12px;
    background-color: #232528;
    box-shadow: -4px 4px 4px #00000090;
}

.footer-central-3:hover {
    border: 2px solid var(--hover);
}