.cheader-gradient-wrap {
    width: 100%;
    height: 140px;
    background-color: white;

}

.cheader-gradient {
    width: 100%;
    height: 100%;
    background: linear-gradient(333deg, var(--hover), var(--green));
    color: white;
}

.cheader-gradient-selects {
    width: calc(100% - 36px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 18px;
}

.chatgoback {
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: #ffffff50;
    border-radius: 16px;
    margin: 3px 0 0 3px;
    border: 1px solid transparent;
}

.chatgoback-close:hover {
    background-color: tomato;
    border: 1px solid white;
}