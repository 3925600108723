.GreenBorderButton {

    font-weight: bolder;
    border-radius: 8px;
    background-color: transparent;

    width: calc(100% - 2px);
    height: 41px;

    margin-top: 14px;
}