#CMenuManageFraud {
    width: calc(100vw - 60px);
    background-color: var(--dark);
    padding: 60px 30px;
    color: white;
    text-align: center;

}

.CMenuManageFraud-a {
    font-size: 30px;
    font-weight: bold
}

.CMenuManageFraud-b {
    font-size: 38px;
    font-weight: bolder;
    padding: 22px 0
}

.CMenuManageFraud-c {
    margin-top: 0;
    color: #c4c4c4;
}

.CMenuManageFraud-d {
    margin: 0;
    color: #c4c4c4;
}

.CMenuManageFraud-e {
    font-size: 36px;
    font-weight: bold;
}

.CMenuManageFraud-middle-div,
.CMenuManageFraud-btn {
    --wdth: 60%;
}

.CMenuManageFraud-middle-div {
    width: var(--wdth);
    margin-left: 20%;
}

.CMenuManageFraud-li {
    margin: 20px 0;
}

.CMenuManageFraud-li--2 {
    margin: 40px 0;
}

.CMenuManageFraud-i {
    margin: 10px 0;
}

.CMenuManageFraud-btn {
    padding: 20px 0;
    width: var(--wdth);
    background-color: var(--green);
    border-radius: 14px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    text-shadow: 1px 1px 2px #00000040;
}

#CMenuManageFraud-ol {
    list-style: none;
    counter-reset: item;
}

#CMenuManageFraud-ol li {
    counter-increment: item;
    margin-bottom: 5px;
}

#CMenuManageFraud-ol li:before {
    margin-right: 10px;
    content: counter(item);
    background: var(--dark);
    border-radius: 4px;
    color: white;
    width: 1.2em;
    text-align: center;
    display: inline-block;
}

.cmenufraud-b1 {
    background-color: #c4c4c4;
    width: 60%;
    height: 1px;
}

@media only screen and (max-width: 960px) {

    .cmenufraud-b1 {
        width: 92%;
    }

    .CMenuManageFraud-middle-div,
    .CMenuManageFraud-btn {
        --wdth: 90%;
    }

    .CMenuManageFraud-middle-div {
        width: var(--wdth);
        margin-left: 5%;
    }

    .CMenuManageFraud-btn {
        width: var(--wdth);
    }

    .CMenuManageFraud-e {
        font-size: 26px;
        font-weight: bold;
    }
}