#T-Header {
    --logo-icons-input-height: 60%;
}

.T-Header-Container {
    position: fixed;
    width: 100%;
    box-shadow: 0 12px 14px 0 rgb(0 0 0 / 20%);
    z-index: 99;
}

#T-Header {
    width: 100%;
    height: var(--header-height);
    background-color: var(--green);
    display: flex;
    justify-content: center;
}

.T-h-center {
    width: 90%;
    height: 100%;
    display: flex;
    align-self: center;
}

.T-h-c-l-img-wrap {
    height: var(--header-height);
    width: 100%;

     
    display: flex;
    justify-content: flex-start;
    align-items: center;

    color: #fff;
    text-decoration: none;
}

.T-h-c-l-img { display: none; }

.T-h-c-l-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    width: 100%;
}
.T-h-c-l-title h1{
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    font-weight: 600;
}


@media only screen and (max-width: 960px) {
    .T-h-c-l-img {
        margin: 0 8px 0 -4px;
        display: block;
        width: 30px;
        height: 30px;
    }

    .T-h-c-l-img-wrap {
        display: flex;
        justify-content: center;
        width: fit-content;
    }
    
    .T-h-c-l-title {
        display: none;
    }
}